import React from 'react'
import './process.scss';


const Process = () => {
  return (
    <div className='process'>
        <div className='process-head'>
                <h1>Our Process</h1>
            </div>
        <div className='process-container'>

            
            <div className='process-item'>
                <img src={require('../assets/img/form.png')} alt='no image'/>
                <h2>Fill Up Application Form</h2>
            </div>
            <div className='process-item gbg'>
                <img src={require('../assets/img/payment.png')} alt='no image'/>
                <h2>Make Online <br/>Payment</h2>
            </div>
            <div className='process-item '>
                <img src={require('../assets/img/customer.png')} alt='no image'/>
                <h2>Executive will process Application</h2>
            </div>
            <div className='process-item gbg'>
                <img src={require('../assets/img/mail.png')} alt='no image'/>
                <h2>Get Confirmation on Mail</h2>
            </div>


        </div>

    </div>
  )
}

export default Process