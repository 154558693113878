import React from 'react'
import './services.scss';
import VerticalTabs from './service-show/servicesshow';




const Services = () => {

  return (
    <div className='services'>
        <div className='services-container'>
            <div className='services-head'>
                <h1>We Provide</h1>
                <h1>Best Quality Services</h1>
            </div>
            <div className='services-content'>
                <VerticalTabs />
                
            </div>


        </div>
    </div>
  )
}

export default Services