import React from 'react';
import './copyrightregistration.scss';
import Form from '../../form/form';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Copyrightregistration= () => {

    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='copy'>
        <div className='copy-container'>
            <div className='copy-content1' >
                <div className='copy-content1-container'>
                    <div className='content1'>
                        <h1>Copyright Registration   </h1>
                        <p>copy Returns Filing in 7 business days</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>Copyright Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='copy-content2'>
                <div className='copy-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='copy-content3'>
                <div className='copy-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Overview of Copyright Registration </h2>
                            <p>Copyright registration is a legal process that provides authors, creators, and artists with formal recognition and protection for their original works. These works can include literary, artistic, musical, and other creative content. The process typically involves submitting an application to the appropriate copyright office, which can be either a national or an international entity, depending on the jurisdiction. </p>
                            <p>Once the copyright is registered, the owner of the work gains exclusive rights to reproduce, distribute, display, and perform the copyrighted material. This means that others must obtain permission from the copyright owner to use, copy, or distribute the work in any way. </p>
                            <p>It's important to note that copyright protection is automatically granted to the creator upon the creation of an original work. However, registering the copyright provides additional legal benefits, such as the ability to sue for statutory damages and attorney's fees in case of infringement. </p>
                            <p> </p>
                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of Copyright Registration In India </h2>
                            
                            <p><b>Legal Protection: </b>Copyright registration provides legal evidence of ownership and authorship of your creative work. It establishes a public record of your rights, making it easier to defend your content against unauthorized use or infringement. </p>
                            <p><b>Deterrent to Infringement:  </b>Once registered, your copyright becomes more visible to potential infringers, acting as a deterrent against unauthorized copying or use of your work. </p>
                            <p><b>Lawsuit Eligibility:  </b>Copyright registration is a prerequisite for filing a copyright infringement lawsuit in India. If someone copies your work without permission, you can take legal action to protect your rights. </p>
                            <p><b>Global Recognition:  </b>Indian copyright registration provides protection in many countries worldwide due to international treaties and agreements, enhancing the global recognition of your creative work. </p>
                            <p><b>Duration of Protection:  </b>Copyright protection typically lasts for the lifetime of the author plus 60 years after their death, ensuring long-term protection for the creator's heirs or successors. </p>
                            <p><b>Presumption of Ownership: </b>Copyright registration creates a presumption in court that the information in the certificate of registration is correct, making it easier to establish your ownership in legal disputes. </p>
                           
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents</h2>
                            <p>copy </p>

                                <ul>
                                    <li>Customer copyIN</li>
                                    <li>Type of Invoice</li>
                                    <li>Place of Supply</li>
                                    
                                </ul>
                            

                        </div>
                        <div className='content2-c1' >
                           
                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>pv</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Who </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Any </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>How</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>What </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>You</Typography>
                                    <Typography><ul>
                                        <li>Sales </li>
                                        <li>Details </li>
                                        
                                    </ul></Typography>
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> Can </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes,</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Failing</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Do </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  While</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Copyrightregistration