import React from 'react';
import './nidhicompany.scss';
import Form from '../../form/form';


import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Nidhicompany = () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='pvt'>
        <div className='pvt-container'>
            <div className='pvt-content1' >
                <div className='pvt-content1-container'>
                    <div className='content1'>
                        <h1 >Nidhi Company Registration </h1>
                        <p>An excellent option for people looking to save money and lead a thrifty lifestyle.</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>Nidhi Company Registration </h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='pvt-content2'>
                <div className='pvt-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='pvt-content3'>
                <div className='pvt-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Nidhi Company Registration - An Overview</h2>
                            <p>Who Should Register For A Nidhi Company? Nidhi companies can be registered for cultivating thrift habits. Nidhi Company Registration is a procedure for incorporation of a NBFC called Nidhi. Know all about Nidhi Registration, documents required for Nidhi Company Registration. Vanigasevai allows you to register a Nidhi company online.Are you preparing to start a finance business without too much capital investing? If your answer is yes, Nidhi Company is the logical choice. Nidhi Company is one kind of Non-Banking Financial Company (NBFC) which is an excellent choice of company for individuals looking to save money and lead a careful business and life.</p>
                            <p>So, it is always better to complete the Nidhi Limited Registration online. Nidhi Company Registration Online in India is an NBFC or Non-banking financing company. It was regulated by the Ministry of Corporate Affairs and registered under Section 406 of the Companies Act, 2013. The RBI monitors all transactions relating to financial matters. Nidhi Company's primary business is to lend money to its core members. The company members or shareholders are asked to save and invest money in the company. These invested deposits are then utilized by the company members or shareholders to grant advances or loans, and to obtain government-issued stocks, debentures, bonds, securities and so on. </p>

                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of a Nidhi Company</h2>
                            <p> A Nidhi company, also known as a mutual benefit company, is a non-banking financial institution in India that promotes the habit of savings among its members. Here are some benefits of a Nidhi company:</p>
                            <p><b>Easy Formation: </b> Setting up a Nidhi company is relatively simple and requires fewer legal formalities compared to other financial institutions. </p>
                            <p><b>Limited Liability: </b> Members of a Nidhi company enjoy limited liability, meaning their personal assets are protected in case of any financial losses incurred by the company. </p>
                            <p><b>Encourages Saving Habits: </b> Nidhi companies primarily focus on promoting a savings culture among its members. By encouraging regular savings and offering attractive interest rates on deposits, they help individuals develop a disciplined approach to financial planning. </p>
                            <p><b>Collective Financial Assistance: </b> Nidhi companies provide financial assistance to their members through various means, such as providing loans against fixed deposits or extending personal loans. This can be especially helpful for individuals who may not have access to traditional banking services. </p>
                            <p><b>Affordable Credit: </b> Nidhi companies often offer loans at reasonable interest rates, making credit more accessible and affordable to their members. This can be particularly beneficial for small business owners or individuals in need of financial support. </p>
                            <p><b>Member-Focused Approach: </b> Nidhi companies prioritize the welfare of their members and aim to provide financial solutions tailored to their specific needs. This member-centric approach fosters a sense of trust and loyalty among the members. </p>
                            <p><b>Regulatory Framework: </b> Nidhi companies operate under the guidelines and regulations set by the Ministry of Corporate Affairs in India. This regulatory framework ensures transparency, accountability, and protection for the members. </p>
                            <p><b>Socially Responsible: </b> Many Nidhi companies actively participate in community development activities, supporting initiatives that contribute to the overall welfare of society. This promotes social responsibility and creates a positive impact on the local community. </p>
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents</h2>
                            <p>pvt </p>

                            <h4>Memorandum of Association (MOA): </h4>
                                <ul>
                                    <li> The MOA outlines the company's objectives, powers, and rules for internal governance. It includes details such as the company's name, registered office address, and objectives of the Nidhi company.  </li>
                                </ul>
                            <h4>Articles of Association (AOA): </h4>
                                <ul>
                                    <li> The AOA contains the company's bylaws, rules, and regulations for its operations. It specifies the rights and duties of the members, borrowing powers, and the process for conducting meetings.  </li>
                                </ul>
                            <h4>Incorporation Certificate: </h4>
                                <ul>
                                    <li> This document is issued by the Registrar of Companies (RoC) upon the successful registration of the Nidhi company. It serves as proof that the company has been legally incorporated.  </li>
                                </ul>
                            <h4>PAN Card:  </h4>
                                <ul>
                                    <li>The Permanent Account Number (PAN) card is a unique ten-digit alphanumeric identifier issued by the Income Tax Department. It is required to open a bank account and for various financial transactions.  </li>
                                </ul>
                            <h4>Identity Proof: </h4>
                                <ul>
                                    <li> Individuals associated with the Nidhi company, such as directors and shareholders, need to provide a valid identity proof document. This can include a copy of their Aadhaar card, passport, driving license, or voter ID card.  </li>
                                </ul>
                            <h4>Address Proof: </h4>
                                <ul>
                                    <li> Similarly, address proof documents are necessary to establish the residential address of the company's directors and shareholders. Utility bills, bank statements, or rental agreements can be submitted as address proof.  </li>
                                </ul>
                            <h4>Passport-sized Photographs: </h4>
                                <ul>
                                    <li> Recent passport-sized photographs of the directors and shareholders are required for various official purposes, such as filing forms and obtaining necessary approvals.  </li>
                                </ul>
                            <h4>Director Identification Number (DIN): </h4>
                                <ul>
                                    <li> Directors of a Nidhi company must obtain a unique Director Identification Number (DIN) from the Ministry of Corporate Affairs (MCA). This number serves as an identity for directors and is used for official filings and communication.  </li>
                                </ul>
                            <h4>Digital Signature Certificate (DSC): </h4>
                                <ul>
                                    <li> A Digital Signature Certificate is necessary for online filings and to ensure the authenticity and integrity of electronic documents submitted to government authorities.  </li>
                                </ul>
                            <h4>Bank Account Details: </h4>
                                <ul>
                                    <li> The Nidhi company needs to open a dedicated bank account in its name. The bank account details, including the account number and bank branch, need to be provided for registration and subsequent operations.  </li>
                                </ul>
                            

                        </div>
                        {/* <div className='content2-c1' >
                            <h2>pvt Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of pvt returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (pvt) regulations. Here, we provide a simple breakdown of the various pvt return types and their associated deadlines.</p>
                           
                                <p><b>pvtR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>pvtR-3B:</b>pvtR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div> */}
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on Nidhi Company in India  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is a Nidhi Company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A Nidhi Company is a type of non-banking financial institution in India that facilitates savings and lending activities among its members. It operates on the principle of mutual benefit, where members contribute to a common fund and can borrow from it when needed.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>How is a Nidhi Company different from a regular bank? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Unlike traditional banks, Nidhi Companies only serve their members and focus on promoting savings and thrift. They are not authorized to carry out full-scale banking activities like accepting deposits from the public. Nidhi Companies are regulated by the Ministry of Corporate Affairs (MCA) and operate under specific legal provisions. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>Who can become a member of a Nidhi Company?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Any individual, partnership firm, or company can become a member of a Nidhi Company. The primary objective is to promote savings habits and cater to the financial needs of its members. However, it's important to note that Nidhi Companies cannot accept deposits or provide loans to non-members. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>What are the requirements for starting a Nidhi Company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>To start a Nidhi Company, you need a minimum of seven members, and all of them must be Indian citizens. The company must have a minimum capital of Rs. 5 lakh, and certain documents, such as the Memorandum of Association (MOA) and Articles of Association (AOA), need to be drafted and filed with the Registrar of Companies (ROC).</Typography>
                                    
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> What are the benefits of starting a Nidhi Company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>   Starting a Nidhi Company can provide various benefits, such as encouraging savings habits, providing access to affordable credit within the community, and promoting financial inclusion. Additionally, Nidhi Companies enjoy certain tax exemptions and have simplified compliance requirements compared to other financial institutions.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>Can a Nidhi Company raise funds from the public? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  No, Nidhi Companies cannot raise funds from the public. They are restricted to accepting deposits and providing loans solely to their registered members. This limitation ensures that the company operates within the defined framework and protects the interests of its members.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Nidhicompany