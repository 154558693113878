import React from 'react';
import './whyvanigasevai.scss';

const Whyvanigasevai = () => {
  return (
    <div className='why'>
        <div className='why-container'>
            <div className='why-head'>
                <h1>Why Vanigasevai ?</h1>
            </div>
            <div className='why-content'>
                <div className='content1'>
                <img src={require('../../assets/img/icon1.png')} alt='no image'/>
                    <h3>Happy coustmer</h3>

                </div>
                <div className='content1'>
                <img src={require('../../assets/img/icon2.png')} alt='no image'/>
                    <h3>On Time Service</h3>

                </div>
                <div className='content1'>
                <img src={require('../../assets/img/icon3.png')} alt='no image'/>
                    <h3>Customer Support</h3>

                </div>
                <div className='content1'>
                <img src={require('../../assets/img/icon4.png')} alt='no image'/>
                    <h3>Quality Services</h3>

                </div>
                <div className='content1'>
                <img src={require('../../assets/img/icon5.png')} alt='no image'/>
                    <h3>Data Privacy</h3>

                </div>
                <div className='content1'>
                <img src={require('../../assets/img/icon6.png')} alt='no image'/>
                    <h3>Experienced Professionals</h3>

                </div>
                <div className='content1'>
                <img src={require('../../assets/img/icon1.png')} alt='no image'/>
                    <h3>Happy coustmer</h3>

                </div>
                


            </div>
        </div>

    </div>
  )
}

export default Whyvanigasevai