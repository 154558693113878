import React from 'react';
import './duediligence.scss';
import Form from '../../form/form';


import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Duediligence = () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='due'>
        <div className='due-container'>
            <div className='due-content1' >
                <div className='due-content1-container'>
                    <div className='content1'>
                        <h1 >Get Due Diligence</h1>
                        <p>due Returns Filing in 7 business days</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>due Returns Filing</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='due-content2'>
                <div className='due-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='due-content3'>
                <div className='due-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Due Diligence - An Overview</h2>
                            <p>Due diligence is a crucial process that individuals, businesses, and organizations undertake before entering into various agreements or making important decisions. It involves conducting a comprehensive investigation and analysis to ensure that all relevant information is gathered and evaluated to make informed choices. In this article, we will provide a simple overview of due diligence and its significance in different contexts.</p>

                        </div>
                        <div className='content2-c1' >
                            <h2>What is Due Diligence? </h2>
                            <p>Due diligence is a systematic and objective examination of facts, documents, and information related to a specific matter or transaction. It aims to uncover potential risks, liabilities, and opportunities associated with the subject under investigation. By conducting due diligence, individuals or organizations can assess the reliability, credibility, and financial health of parties involved, identify potential legal or financial issues, and make informed decisions based on the gathered information. </p>
                            
                        </div>
                        <div className='content2-c1' >
                            <h2>Types of Due Diligence:</h2>

                            <ol>
                                <li><b>Financial Due Diligence: </b> This type focuses on assessing the financial aspects of a company or investment opportunity. It involves analyzing financial statements, cash flows, assets, liabilities, and other financial indicators to evaluate the financial health and viability of the subject.  </li>
                                <li><b>Legal Due Diligence: </b> Legal due diligence entails a thorough examination of legal documents, contracts, licenses, permits, and other legal aspects relevant to a transaction or business. It aims to identify any legal risks, obligations, or potential liabilities that may arise from the subject of investigation.  </li>
                                <li><b>Commercial Due Diligence: </b> Commercial due diligence is commonly performed in mergers and acquisitions or business partnerships. It involves analyzing market trends, competition, customer base, growth potential, and other commercial factors to assess the commercial viability and potential synergies of the transaction.  </li>
                                <li><b>Environmental Due Diligence: </b> Environmental due diligence focuses on assessing the environmental risks and compliance issues associated with a property or business. It involves evaluating factors such as pollution, contamination, permits, and regulatory compliance to determine potential environmental liabilities.  </li>
                                
                            </ol>

                            <h2>Significance of Due Diligence:</h2>
                            <p>Due diligence plays a crucial role in various scenarios, including:</p>
                            <ol>
                                <li><b>Business Transactions: </b> Before acquiring a company, investing in a new venture, or entering into partnerships, conducting due diligence helps mitigate risks and uncover crucial information that could impact the success or failure of the transaction.  </li>
                                <li><b>Real Estate: </b> When buying or leasing property, due diligence helps identify potential legal, environmental, or financial issues associated with the property, ensuring informed decisions are made.  </li>
                                <li><b>Legal Compliance: </b> Due diligence assists in ensuring compliance with legal and regulatory requirements, thereby minimizing the risk of penalties, litigation, and reputational damage.  </li>
                                <li><b>Financial Investments: </b> Conducting due diligence before investing in stocks, bonds, or other financial instruments allows individuals to assess the financial health, performance, and risks associated with the investment.  </li>
                                
                            </ol>
                            
                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of Due Diligence  </h2>
                            <p> <b>Legal Compliance: </b> By conducting due diligence, you can ensure that the content you create is free from any copyright violations or legal issues. This helps protect you and your website from potential legal action and penalties. </p>
                            <p><b>Originality and Authenticity: </b> Due diligence ensures that the content you produce is original and authentic. It involves checking sources, verifying information, and providing accurate and reliable content to your website visitors. This enhances your credibility and reputation as a trustworthy source of information. </p>
                            <p> <b>Risk Mitigation: </b> By conducting due diligence, you minimize the risk of unintentionally using copyrighted materials without permission. It helps you avoid potential disputes, costly legal battles, and damage to your brand's reputation. </p>
                            <p><b>Better User Experience: </b> Copyright-free content provides a positive user experience by offering fresh and unique information to your website visitors. Users are more likely to trust and engage with your website if they know that the content is reliable and legally compliant. </p>
                            
                        </div>
                        {/* <div className='content2-c1' >

                            <h2>Documents</h2>
                            <p>due </p>

                                <ul>
                                    <li>Customer dueIN</li>
                                    <li>Type of Invoice</li>
                                    <li>Place of Supply</li>
                                    
                                </ul>
                            

                        </div> */}
                        {/* <div className='content2-c1' >
                            <h2>due Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of due returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (due) regulations. Here, we provide a simple breakdown of the various due return types and their associated deadlines.</p>
                           
                                <p><b>dueR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>dueR-3B:</b>dueR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div> */}
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on  Due Diligence   </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is due diligence? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography> Due diligence refers to the process of conducting thorough research and investigation into a business or individual before entering into a transaction or making a decision. It involves assessing the financial, legal, and operational aspects to ensure that all relevant information is considered.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography> Why is due diligence important? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Due diligence is crucial because it helps mitigate risks and provides a comprehensive understanding of the potential benefits and drawbacks associated with a business or investment opportunity. It allows individuals or organizations to make informed decisions based on factual information. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>What does a due diligence process involve?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The due diligence process typically involves gathering and reviewing various documents, such as financial statements, legal contracts, licenses, permits, and regulatory compliance records. It may also include conducting interviews, site visits, and analysis of market trends or industry benchmarks. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>Who performs due diligence? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Due diligence can be performed by individuals or organizations themselves, or they can hire professionals such as lawyers, accountants, consultants, or specialized due diligence firms to carry out the process on their behalf. The level of expertise required depends on the complexity of the transaction or decision.</Typography>
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography>  What are the common types of due diligence? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography> The most common types of due diligence include financial due diligence, legal due diligence, commercial due diligence, operational due diligence, and environmental due diligence. Each type focuses on specific aspects relevant to the nature of the transaction or decision being evaluated.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>How long does due diligence usually take? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The duration of due diligence can vary depending on the complexity of the matter and the availability of information. It can range from a few weeks to several months. Timelines can be influenced by factors such as the size of the business, the number of stakeholders involved, and the scope of the investigation.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>What are the potential outcomes of due diligence? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The outcomes of due diligence can vary. It may reveal positive findings, such as strong financial performance, compliance with laws and regulations, and a favorable market outlook. On the other hand, it could uncover risks, such as financial instability, legal issues, operational inefficiencies, or undisclosed liabilities. The findings help stakeholders make informed decisions and negotiate favorable terms.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel8a-content" id="panela-header" >
                                    <Typography>How can due diligence benefit businesses? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>   Due diligence can benefit businesses in several ways. It helps identify potential risks and opportunities, enhances transparency and accountability, improves negotiation positions, and increases the chances of successful outcomes. It also provides stakeholders with a comprehensive understanding of the business, enabling better strategic planning and risk management.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Due Diligence</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Duediligence