import React from 'react';
import './trademarkregistration.scss';
import Form from '../../form/form';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Trademarkregistration= () => {

    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='trade'>
        <div className='trade-container'>
            <div className='trade-content1' >
                <div className='trade-content1-container'>
                    <div className='content1'>
                        <h1>Trademark Registration  </h1>
                        <p>trade Returns Filing in 7 business days</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>trade Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='trade-content2'>
                <div className='trade-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='trade-content3'>
                <div className='trade-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2> Trademark Registration in India - An Overview </h2>
                            <p>Trademark registration in India provides legal protection for brand names, logos, and symbols, preventing others from using them without permission. It is a crucial step for businesses to safeguard their unique identity and establish a distinctive market presence. In this article, we'll provide a simple overview of the trademark registration process in India.</p>

                            <h2>Why Is Trademark Registration Important? </h2>
                            <p>Trademark registration is essential for businesses and individuals as it provides exclusive ownership, protects brand identity, builds consumer trust, allows legal recourse against infringers, and facilitates nationwide and international protection. With a registered trademark, businesses can safeguard their reputation, deter potential imitators, and establish a valuable asset that supports growth and expansion both offline and in the digital landscape.</p>
                        </div>
                        
                        <div className='content2-c1' >

                            <h2>DocuDocuments Required for Online Trademark Registration in Indiaments</h2>
                            <p>If you're a business owner or an individual seeking to protect your brand identity, registering a trademark in India is a crucial step. An online trademark registration process in India has simplified the procedure, making it more accessible to entrepreneurs and businesses. To get started, you'll need to gather a few essential documents to ensure a smooth and successful application. In this guide, we'll outline the necessary documents required for trademark registration in India. </p>

                            <h4>Applicant's Information: </h4>
                                <ul>
                                    <li>The first set of documents includes information about the applicant(s) seeking trademark registration. If you are an individual, provide a copy of your valid identity proof (such as Aadhar card or passport). For businesses, you'll need a copy of the company's registration certificate or partnership deed, depending on the type of organization. </li>
                                </ul>
                            <h4>Trademark Representation: </h4>
                                <ul>
                                    <li>The next step is to submit the representation of your trademark. This can be in the form of a graphical representation, a logo, or a brand name. It's essential to provide the image in the prescribed format and size as specified by the trademark authorities. </li>
                                </ul>
                            <h4>Trademark Specimen: </h4>
                                <ul>
                                    <li>To demonstrate the actual use of the trademark, you must submit a specimen of the mark. This could be in the form of a product label, packaging, or promotional material displaying the mark. The specimen serves as evidence that the trademark is in use and distinguishes your goods or services from others. </li>
                                </ul>
                            <h4>Power of Attorney (PoA): </h4>
                                <ul>
                                    <li>If you are applying for trademark registration through an agent or attorney, a Power of Attorney document will be required. This document authorizes the representative to act on your behalf during the registration process. </li>
                                </ul>
                            <h4>Goods and Services Classification: </h4>
                                <ul>
                                    <li>While filing for trademark registration, you need to mention the goods and/or services for which you intend to use the trademark. It's essential to provide a list of these goods and services, along with their appropriate classifications, according to the Nice Classification system. </li>
                                </ul>
                            <h4>Priority Document (if applicable): </h4>
                                <ul>
                                    <li>In case you have previously filed for trademark registration in another country and wish to claim priority in India, you will need to submit a certified copy of the priority application as proof. </li>
                                </ul>
                            <h4>Trademark User Affidavit (if applicable): </h4>
                                <ul>
                                    <li>If you've been using the trademark before applying for registration, you may need to provide a user affidavit stating the date of first use and other relevant details.  </li>
                                </ul>
                               
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of Trademark Registration  </h2>
                            <p> When starting a business or creating a brand, one essential step is trademark registration. This legal process provides numerous benefits that protect your brand identity and give your business a competitive edge. Let's explore some of the advantages:</p>
                             
                            <p><b>Legal Protection:  </b>Trademark registration grants you exclusive rights to use your brand name, logo, or slogan in connection with your products or services. This protection helps prevent others from using similar marks that could confuse customers and harm your business. </p>
                            <p><b>Legal Protection:  </b>A registered trademark helps build brand recognition and distinguishes your products or services from others in the market. Customers are more likely to trust and choose a brand with a registered trademark.</p>
                            <p><b>Market Positioning:  </b>Trademarks can be valuable assets that increase the value of your business. As your brand gains recognition and reputation, its market position strengthens, opening up new opportunities for growth. </p>
                            <p><b>Licensing Opportunities: </b>A registered trademark can become a valuable asset that opens up licensing opportunities. You can license others to use your mark, generating additional revenue for your business. </p>
                            <p><b>International Expansion: </b>Trademark registration can be a crucial step if you plan to expand your business globally. Many countries offer reciprocal arrangements that simplify the international registration process. </p>
                            <p><b>Trademark Symbol Use: </b>Once your trademark is registered, you can use the ® symbol, indicating that it is a registered trademark. This symbol adds credibility to your brand and warns others against unauthorized use. </p>
                            
                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on Trademark Registration Online in India  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography> What is a trademark? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A trademark is a unique symbol, word, phrase, logo, design, or combination thereof that distinguishes a company's goods or services from those of others in the market. It helps consumers identify and associate the products with a specific brand.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Why is trademark registration important? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Trademark registration provides legal protection and exclusive rights to the owner over their brand name or logo. It prevents others from using a similar mark, reducing the risk of brand identity theft and confusion in the market. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>Can I register a trademark online in India?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Yes, the process of trademark registration in India can be done online through the official website of the Intellectual Property India (IP India) at ipindia.gov.in. The online registration makes the process faster and more convenient. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>How long does it take to register a trademark? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The timeline for trademark registration can vary, but it usually takes around 18 to 24 months from the date of application to complete the registration process in India. However, you'll receive a Trademark Application Number almost immediately upon submission.</Typography>
                                   
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> What is the difference between "TM" and "®"? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The symbol "TM" stands for "Trademark" and can be used by anyone who claims rights over a mark, even if it is not yet registered. On the other hand, "®" indicates a registered trademark and can only be used once the mark is successfully registered with the appropriate authorities.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>Can I register multiple trademarks under one application? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  No, each trademark must be registered separately. You need to file a separate application for each distinctive mark you wish to protect.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>How long is a registered trademark valid? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Once registered, a trademark is valid for ten years from the date of filing. After ten years, it can be renewed indefinitely in successive periods of ten years each.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Trademarkregistration