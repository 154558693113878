import React from 'react';
import './annualfilingllp.scss';
import Form from '../../form/form';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Annualfillingllp= () => {

    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='afl'>
        <div className='afl-container'>
            <div className='afl-content1' >
                <div className='afl-content1-container'>
                    <div className='content1'>
                        <h1>Annual Filing for LLP</h1>
                        <p>Get your returns filed on a regular basis to avoid penalties for non-compliance. With Vanigasevai, you can avoid heavy penalties by completing your LLP compliances on time!</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>afl Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='afl-content2'>
                <div className='afl-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='afl-content3'>
                <div className='afl-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    
                    <div className='content2'>
                    <div className='content2-c1' >
                            <h2>Overview of Annual Compliance of Limited Liability Partnership (LLP) in India</h2>
                            <p>A Limited Liability Partnership (LLP) is a popular business structure in India, offering a blend of partnership and corporate advantages. LLPs are required to adhere to various annual compliance requirements to ensure regulatory compliance and maintain their legal status. Below is an overview of the annual compliance obligations for LLPs in India:</p>

                        </div>
                        <div className='content2-c1' >
                            <h2> The Advantages of Annual Compliance for Limited Liability Partnerships (LLPs)</h2>
                            <p> Annual compliance is an essential aspect of running a Limited Liability Partnership (LLP) in a well-regulated and efficient manner. It involves fulfilling various statutory requirements and filing necessary documents with the appropriate authorities on a yearly basis. In this article, we explore the significant benefits of adhering to annual compliance for LLPs, highlighting the advantages it brings to the business, partners, and stakeholders.</p>
                            
                            
                            <p>Small</p>
                            
                        </div>
                        
                        <div className='content2-c1' >

                            <h2>Documents</h2>
                            <p>afl </p>

                                <ul>
                                    <li>Customer aflIN</li>
                                    <li>Type of Invoice</li>
                                    <li>Place of Supply</li>
                                    
                                </ul>
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>afl Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of afl returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (afl) regulations. Here, we provide a simple breakdown of the various afl return types and their associated deadlines.</p>
                           
                                <p><b>aflR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>aflR-3B:</b>aflR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>pv</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Who </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Any </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>How</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>What </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>You</Typography>
                                    <Typography><ul>
                                        <li>Sales </li>
                                        <li>Details </li>
                                        
                                    </ul></Typography>
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> Can </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes,</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Failing</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Do </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  While</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Annualfillingllp