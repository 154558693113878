import React from "react";
import './footer.scss'

const Footer = () =>{
    return(
        <div className="footer">
            <div className="footer-container">
            
            <div className="logo">
                <div className="logo-container">
                     <img src={require('../assets/img/logo1.png')} alt='no image'/>
                </div>
            </div>

            <div className="lists res-w">
                <h4>START A BUSINESS</h4>

                <div className="list">
                    <p><a href='/private-limited-company' > Private Limited Company </a></p>
					<p><a href='/limited-liability-partnership' >Limited Liability Partnership  </a></p>
					<p><a href='/one-person-company'>One Person Company  </a></p>
					<p><a href='/partnership-firm-registration' >Partnership Firm Registration   </a></p>
					<p><a href='/sole-proprietorship-company' >Sole Proprietorship Registration  </a></p>
					<p><a href='/nidhi-company-registration' > Nidhi Company Registration   </a></p>
                </div>

            </div>
            <div className=" lists res-w">
                <h4>TAXATION </h4>

                <div className='list'>
                    <p><a href='/gst-registration'  > GST Registration</a></p>
					<p><a href='/gst-returns-filing' >GST Return Filing</a></p>
					<p><a href='' >GST LUT Filing</a></p>
					<p><a href='/tds-return-filing' >TDS Return Filing</a></p>
					<p><a href='/due-diligence' >Due Diligence  </a></p>
					<p><a href='/#' > Professional Tax Registration</a></p>
					<p><a href=''  >Income Tax Return Filing   </a></p>
				</div>
                
            </div>
            <div className="lists res-w">
                <h4>INTELLECTUAL PROPERTY </h4>
                
                <div className='list'>
                    <p><a href='/'   >TDS Return Filing</a></p>
					<p><a href='/ngo-registration' >NGO Registration</a></p>
					<p><a href='/trademark-registration' > Trademark Registration</a></p>
					<p><a href='/copy-right-registration' >Copyright Registration </a></p>
					<p><a href='/patent-registration' >Patent Registration </a></p>
					<p><a href='/80G-12A-registration' >80G and 12A Registration </a></p>
					<p><a href='/#' >Section 8 Company Registration </a></p>


                    
                </div>
            </div>
            <div className="lists res-w">
                <h4>COMPLIANCE</h4>
                
                <div className='list'>
                    <p><a href='/'> Import Export Code  </a></p>
					<p><a href='/' >Annual Compliance for Pvt Ltd</a></p>
					<p><a href='/' >Annual Filing for LLP </a></p>
					<p><a href='/' >Change in Object Clause</a></p>
					<p><a href='/' >Appointment of Directors </a></p>
					<p><a href='/' >Change in Name Clause</a></p>
                </div>
            </div>
            <div className="footer-card res-w">
               <p>We are leading accounting services provider that offers a wide range of services to help businesses and individuals manage their finances.</p>

                    <h4>Get Social With Us</h4>
                    <div className="footer-icons">
                        <div className="footer-icon facebook">
                            <i className="fa fa-facebook"></i>
                        </div>
                        <div className="footer-icon pinterest">
                            <i className="fa fa-pinterest-p"></i>
                        </div>
                        <div className="footer-icon twitter">
                            <i className="fa fa-twitter"></i>
                        </div>
                        <div className="footer-icon instagram">
                            <i className="fa fa-instagram"></i>
                        </div>
                    </div>
                </div>
                <div className="quick-links res-w">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a href='/about'>About Us</a></li>
                        <li><a href='/services'>Services</a></li>
                        <li><a href='/pricing'>Pricing</a></li>
                        <li><a href='/contact'>Contact</a></li>
                    </ul>
                </div>
                <div className="usefull-links res-w">
                    <h4>Useful Links</h4>
                    <ul>    
                        <li><a href='/Privacy policy'>Privacy Policy</a></li>
                        <li><a href='/terms and conditions'>Terms and Conditions</a></li>
                        <li><a href='/refund policy'>Refund Policy</a></li>
                        <li><a href='/'>Support</a></li>
                        <li><a href='/'>FAQ</a></li>
                    </ul>
                </div>
                <div className="address res-w">
                    <h4>Address</h4>
                     <a href="/"> <i class="fa fa-map-marker" aria-hidden="true"></i>SM Towers.,* 6th Floor, Isprout ,Vanigasevai ,Ealim Nesa Giftson Groups,OMR,Perungudi , Chennai-600096. </a>
                     <a href="mailto:vanigasevai@gmail.com"><i class="fa fa-envelope-o" aria-hidden="true"></i>vanigasevai@gmail.com</a>
                     <a href="tel:+919342616702"><i class="fa fa-phone" aria-hidden="true"></i>+91 9342616702</a>

                </div>
                
            </div>
        </div>

        
    );
}

export default Footer;  