import React from 'react';
import './privatelimited.scss';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Startupform from '../startupform/startupform';



const Privatelimited = () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='pvt'>
        <div className='pvt-container'>
            <div className='pvt-content1' >
                <div className='pvt-content1-container'>
                    <div className='content1'>
                        <h1 className='line'>Private Limited (Pvt Ltd) Company</h1>
                        <p>No more delays or difficulties! Register your business with India’s #1 provider of company incorporation services. Get a 7 day Guaranteed document upload to the MCA or receive a full refund</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>Register your company</h3>
                            <Startupform />
                        </div>

                    </div>

                </div>

            </div>
           <div className='pvt-content2'>
                <div className='pvt-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='pvt-content3'>
                <div className='pvt-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Online Company Registration in India - An Overview</h2>
                            <p>Why choose a private limited company structure? Well, it offers several advantages for entrepreneurs and investors. A private limited company provides limited liability protection, which means that the personal assets of the company's shareholders are separate from the company's liabilities. This safeguards your personal finances in case the company faces any legal issues or financial difficulties</p>
                            <p>At Private Limited Company Registration Online in India, we prioritize customer satisfaction and aim to exceed your expectations. With our efficient online platform, you can kickstart your entrepreneurial journey and unlock a world of opportunities.</p>
                            <p>So, why wait? Register your private limited company with us today and embark on a successful business venture. Let us handle the registration process while you focus on realizing your entrepreneurial dreams.</p>
                            <p></p>                            

                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of pvt Return Filing </h2>
                            <p> Registering a private limited company online in India offers several benefits to entrepreneurs and businesses. Here are some key advantages:</p>
                            
                            <p><b>Limited Liability: </b> One of the significant benefits of a private limited company is limited liability protection. The personal assets of the company's shareholders are separate from the company's liabilities. In case of financial losses or debts, shareholders' personal assets are not at risk.  </p>
                            <p><b>Credibility and Trust: </b> Registering as a private limited company enhances the credibility and trustworthiness of your business. It shows potential clients, partners, and investors that you are operating under a recognized legal structure, which can lead to better business opportunities. </p>
                            <p><b>Easy Transferability: </b> The ownership shares of a private limited company can be easily transferred or sold to others. This allows for flexibility in attracting investors, raising funds, or accommodating changes in ownership.  </p>
                            <p><b>Brand Building: </b> Registering a private limited company gives you the opportunity to build a distinct brand identity. It allows you to protect your company name and logo, preventing others from using them without permission. </p>
                            <p><b>Access to Funding: </b> A private limited company can attract funding from various sources, such as venture capitalists, angel investors, or financial institutions. The separate legal entity status and limited liability protection make it more attractive for investors.  </p>
                            <p><b>Tax Advantages: </b> Private limited companies enjoy certain tax benefits. They are eligible for deductions on business expenses, and certain expenditures can be claimed as tax-deductible. Additionally, the corporate tax rate for private limited companies is generally lower than the personal income tax rate. </p>
                                
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents Required for Private Limited Company Registration</h2>
                            <p>The following are the key documents required for private limited company registration in India: </p>

                            <h4>PAN Card </h4>
                                <ul>
                                    <li>PAN Card of shareholders and Directors. A valid passport is a must for foreign nationals.</li>
                                    
                                </ul>

                            <h4>Identity Proof </h4>
                                <ul>
                                    <li>Identity Proof of Shareholders and Directors, such as aadhar card and Voter ID / Passport / Driving License.</li>
                                </ul>
                            <h4>Address Proof </h4>
                                <ul>
                                    <li>Director's Address Proof of Shareholders and Directors, such as the latest Telephone Bill / Electricity Bill / Bank Account Statement.</li>
                                </ul>
                            <h4>Passport size photograph  </h4>
                                <ul>
                                    <li>Another requirement is the Passport size photograph of Shareholders and Directors. Here, it is important to ensure that this is the latest.</li>
                                </ul>
                            <h4>Business Address Proofs </h4>
                                <ul>
                                    <li>Business Address Proofs such as the latest Electricity Bill / Telephone Bill of the registered office address</li>
                                </ul>
                            <h4>NOC  </h4>
                                <ul>
                                    <li>NOC or No Objection Certificate to be obtained from the owner(s) of the registered office</li>
                                </ul>
                            <h4>Rental Agreement </h4>
                                <ul>
                                    <li>The Rental Agreement must be provided if the company's registered office is in a rented office/commercial/other rented premises.</li>
                                </ul>
                            <h4>NRI, documents  </h4>
                                <ul>
                                    <li>NRI, documents</li>
                                </ul>
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>pvt Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of pvt returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (pvt) regulations. Here, we provide a simple breakdown of the various pvt return types and their associated deadlines.</p>
                           

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on Private Limited (Pvt Ltd) Company Registration  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel2a-header" >
                                    <Typography>What is a Private Limited (Pvt Ltd) company?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A Private Limited company is a popular business structure in India, known for its limited liability and separate legal identity. It requires a minimum of two directors and shareholders and restricts the transfer of shares to its members. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Why should I register a Private Limited company?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Registering a Private Limited company offers several advantages. It provides limited liability protection to its owners, enhances credibility, attracts investors, and enables easier fundraising and expansion opportunities. It also allows for perpetual existence and facilitates better tax planning. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>Can I register a Private Limited company online in India?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Yes, you can register a Private Limited company online in India. The Ministry of Corporate Affairs (MCA) has introduced an efficient and user-friendly online registration process called the Integrated Incorporation Form (SPICe+). It simplifies the entire registration process and significantly reduces the time and effort required. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>What are the requirements for Private Limited company registration?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>To register a Private Limited company, you need a minimum of two directors and shareholders (who can be the same individuals), a registered office address in India, valid identity and address proofs of the directors and shareholders, and a unique company name that complies with the MCA guidelines </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> How long does it take to register a Private Limited company online? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  With the online registration process, you can expect to complete the registration within 10-15 days, subject to the availability of necessary documents and information. The MCA has made significant efforts to expedite the registration process and ensure faster incorporation.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What documents are required for Private Limited company registration? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The documents required for Private Limited company registration include identity proofs (such as PAN card, Aadhaar card, or passport) and address proofs (such as utility bills, bank statements, or rental agreement) of the directors and shareholders, along with the address proof of the registered office.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Can I convert an existing business structure into a Private Limited company </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, it is possible to convert an existing business structure, such as a sole proprietorship or partnership, into a Private Limited company. The process involves specific legal procedures, including obtaining the approval of shareholders and following the guidelines provided by the MCA.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Startupform />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Privatelimited