import React, { useState } from 'react';
import axios from 'axios';

const Taxationform = () => {
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();

		const objt = { name, phone, email };

		axios.post('https://sheet.best/api/sheets/94f99d92-15c3-4d5c-9851-82dc5559c233', objt)
			.then((response) => {
				console.log(response);
			});

		// Clear the input fields
		setName('');
		setPhone('');
		setEmail('');
	};

	return (
		<div>
			<form className="form">
				<input
					placeholder="Enter your Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>

				<input
					placeholder="Enter your Phone"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
				/>

				<input
					placeholder="Enter your Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<input type="submit" className='btn' value="Submit" onClick={handleSubmit} />
			</form>
		</div>
	);
};

export default Taxationform;
