import React from "react";

import { BrowserRouter,Routes,Route,Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { stores } from "./store";
import Home from "./home/home";
import Services from "./services/services";
import Header from './header/header';
import Contact from './contact/contact';
import Footer from './footer/footer';
import About from "./about/about";
import ServiceDetails from "./Service-details/service-details";
import Plan from "./ourplan/plan";
import Terms from "./terms/terms";
import Privacy from "./privacypolicy/privacy";
import Refund from "./refund/refund";
import Icons from "./icons/icons";
import Banking from "./banking/banking";
import Gstregistration from "./gst/gstregistration/gstregistration";
import SignUp from "./signUp/signup";

import App from  "./searchbar/App";
import Login from "./login/login";
import Gstreturnsfiling from "./gst/gstreturnsfiling/gstreturnsfiling";
import Privatelimited from "./startup/privatelimited/privatelimited";
import Limitedliability from "./startup/limitedliability/limitedliability";
import Oneperson from "./startup/oneperson/oneperson";
import Partnership from "./startup/partnership/partnership";
import Process from "./process/process";
import Nidhicompany from "./startup/nidhi/nidhicompany";
import Soleproprietorship from "./startup/soleproprietorship/soleproprietorship";
import Tdsreturnfiling from "./gst/tdsreturnfiling/tdsreturnfiling";
import Duediligence from "./gst/duediligence/duediligence";
import Whyvanigasevai from "./whyvanigasevai/New folder/whyvanigasevai";
import Incometax from "./gst/incometax/incometax";
import Professionaltax from "./gst/professionaltax/professionaltax";
import Ngoregistration from "./intellectual property/ngoregistration/ngoregistration";
import Copyrightregistration from "./intellectual property/copyrightregistration/copyrightregistration";
import Trademarkregistration from "./intellectual property/trademarkregistration/trademarkregistration";
import Patentregistration from "./intellectual property/patentregistration/patentregistration";
import Gregistration from "./intellectual property/12gregistration/12gregistration";
import Importexport from "./compliance/importexport/importexport";
import Annualfillingllp from "./compliance/annualfilingllp/annualfilingllp";
import Annualcompliance from "./compliance/annualcompliance/annualcompliance";
import Changeobject from "./compliance/changeobject/changeobject";
import Changename from "./compliance/Changename/Changename";
import Appointmentdirectors from "./compliance/appointmentdirectors/appointmentdirectors";
import Eventcompliances from "./compliance/eventcompliances/eventcompliances";
import OurClients from "./Ourclients/Ourclients";


const Redux = () =>{
    return(
        <>
           <Provider store={stores}>
                <Routing/>
           </Provider> 
        </>
    );
}
const Main = () =>{
    return(
        <>
            <Home />
            
            <Services />
            <Process />
            <Plan />
            <Whyvanigasevai />
            <OurClients />
             <Banking />
            <Contact />

            
        </>
    );
}


const Routing = () =>{


    return(
        <>
            <BrowserRouter>
                
                    <Header/>
                    <Icons />
                    <Routes>
                        <Route path='/sign-up' element={<SignUp/>} />
                        <Route path='/login' element={<Login/>} />


                        
                        <Route path='/' element={<Main/>} />
                        <Route path='/about' element={<About/>} />
                        <Route path='/contact' element={<Contact/>} />
                        <Route path='/services' element={<Services/>} />
                        
                        <Route path='/Service-details/:index' element={<ServiceDetails/>} />
                        <Route path='/pricing' element={<Plan/>} />
                        <Route path='/banking' element={<Banking />} />
                        <Route path='/Privacy policy' element={<Privacy/>} />
                        <Route path='/terms and conditions' element={<Terms/>} />
                        <Route path='/refund policy' element={<Refund/>} />

                        {/* gst */}
                        <Route path='/gst-registration' element={<Gstregistration />} />
                        <Route path='/gst-returns-filing' element={<Gstreturnsfiling />} />
                        <Route path='/tds-return-filing' element={<Tdsreturnfiling />} />
                        <Route path='/due-diligence' element={<Duediligence />} />
                        <Route path='/income-tax' element={<Incometax />} />
                        <Route path='/professional-tax' element={<Professionaltax />} />
                       
                        {/* startup */}
                        <Route path='/private-limited-company' element={<Privatelimited />} />
                        <Route path='/limited-liability-partnership' element={<Limitedliability />} />
                        <Route path='/one-person-company' element={<Oneperson />} />
                        <Route path='/partnership-firm-registration' element={<Partnership />} />
                        <Route path='/nidhi-company-registration' element={<Nidhicompany />} />
                        <Route path='/sole-proprietorship-company' element={<Soleproprietorship />} />


                        <Route path='/ngo-registration' element={<Ngoregistration />} />
                        <Route path='/trademark-registration' element={<Trademarkregistration />} />
                        <Route path='/copy-right-registration' element={<Copyrightregistration />} />
                        <Route path='/patent-registration' element={<Patentregistration />} />
                        <Route path='/80G-12A-registration' element={<Gregistration />} />
                        
                        
                        <Route path='/annual-filling-llp' element={<Annualfillingllp />} />
                        <Route path='/annual-compliance' element={<Annualcompliance />} />
                        <Route path='/appointment-directors' element={<Appointmentdirectors />} />
                        <Route path='/change-object' element={<Changeobject />} />
                        <Route path='/change-name' element={<Changename />} />
                        <Route path='/event-compliances' element={<Eventcompliances />} />
                        <Route path='/import-export' element={<Importexport />} />
                        

                        <Route path='/app' element={<App />} />

                        <Route path='*' element={<Navigate to={'/'} />}/>
                    </Routes>

                    <Footer/>
            </BrowserRouter>
        </>
    );
}

export default Redux;

