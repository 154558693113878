import React from 'react';
import './ngoregistration.scss';
import Form from '../../form/form';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Ngoregistration= () => {

    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='ngo'>
        <div className='ngo-container'>
            <div className='ngo-content1' >
                <div className='ngo-content1-container'>
                    <div className='content1'>
                        <h1>NGO Registration Online India </h1>
                        <p>gister to become eligible to receive tax exempted grants, donations, and funding from government and non-governmental organisations.</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>NGO Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='ngo-content2'>
                <div className='ngo-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='ngo-content3'>
                <div className='ngo-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>NGO Registration - An Overview</h2>
                            <h4>What is an NGO?</h4>
                            <p>An NGO, or Non-Governmental Organization, is a non-profit entity that operates independently of government influence. NGOs are driven by the mission to address various social, environmental, and humanitarian issues. They play a crucial role in creating positive change and advocating for the betterment of society.</p>
                            <h4>Why Register an NGO?</h4>
                            <p>Registering an NGO formalizes its existence and provides legal recognition. It offers several benefits, such as:</p>
                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of NGO Registration </h2>
                            <p> Registering an NGO (Non-Governmental Organization) can offer several benefits that enable it to make a positive impact on society. Here are some simple, copyright-free points to consider:</p>
                            <p><b>Legitimacy and Credibility: </b>NGO registration lends an official status to the organization, establishing trust and credibility among stakeholders, donors, and beneficiaries. </p>
                            <p><b>Access to Funding: </b> Registered NGOs gain eligibility for various government grants, private funding, and international aid, enhancing their financial resources for social projects. </p>
                            <p><b>Tax Exemptions:</b> NGOs often receive tax benefits and exemptions, allowing them to utilize funds more effectively for their charitable activities. </p>
                            <p><b>Legal Recognition:  </b> Registration grants the NGO a legal entity, enabling it to enter into contracts, open bank accounts, and conduct operations more smoothly.</p>
                            <p><b>Networking Opportunities: </b>Registered NGOs can participate in workshops, seminars, and conferences, fostering collaboration with other organizations and sharing best practices. </p>
                            <p><b>Advocacy and Lobbying:  </b>Formal registration allows NGOs to engage in advocacy and lobbying efforts, influencing policies and bringing attention to critical social issues. </p>
                            
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents Required for NGO Registration</h2>
                            <p>Registering a non-governmental organization (NGO) is a crucial step towards making a positive impact on society. To ensure a smooth and successful registration process, it's essential to prepare the necessary documents. Below is a simple list of documents required for NGO registration: </p>

                            <h4>Memorandum of Association (MoA): </h4>
                                <ul>
                                    <li>The MoA outlines the NGO's objectives, mission, and the scope of activities it intends to undertake. It defines the purpose and goals of the organization and serves as a blueprint for its operations.  </li>
                                </ul>
                            <h4>Articles of Association (AoA): </h4>
                                <ul>
                                    <li>The AoA contains the rules and regulations that govern the internal functioning of the NGO. It covers aspects such as the structure of the organization, roles and responsibilities of members, and the decision-making process.  </li>
                                </ul>
                            <h4>Registration Application Form: </h4>
                                <ul>
                                    <li>The NGO registration application form is provided by the relevant government authority responsible for NGO registrations. It requires essential details such as the organization's name, address, objectives, and contact information of its key members. </li>
                                </ul>
                            <h4>Proof of Address: </h4>
                                <ul>
                                    <li>The NGO must submit valid proof of its office address, such as a rental agreement or utility bill, to establish its physical presence.  </li>
                                </ul>
                            <h4>Identity Proof of Members: </h4>
                                <ul>
                                    <li>Identity documents, such as passports, voter ID cards, or Aadhar cards, of all the key members and stakeholders involved in the NGO need to be submitted.  </li>
                                </ul>
                            <h4>Passport-sized Photographs: </h4>
                                <ul>
                                    <li>Recent passport-sized photographs of the key members, often required for affixing to the application forms.  </li>
                                </ul>
                            <h4>Certificate of Incorporation (if applicable): </h4>
                                <ul>
                                    <li>If the NGO is registered as a company or any other legal entity, the Certificate of Incorporation issued by the respective authority must be provided.  </li>
                                </ul>
                            <h4>Bank Account Details: </h4>
                                <ul>
                                    <li>The NGO should furnish its bank account details, including a canceled cheque or a bank statement, to verify its financial transactions.  </li>
                                </ul>
                            <h4>Financial Statements: </h4>
                                <ul>
                                    <li>Depending on the registration laws in the country, financial statements such as income and expenditure statements, balance sheets, and auditor reports may be required.  </li>
                                </ul>
                            <h4>No Objection Certificate (NOC) from the Landlord: </h4>
                                <ul>
                                    <li>If the NGO operates from a rented property, an NOC from the landlord permitting its use for NGO activities is typically required.  </li>
                                </ul>
                            <h4>Declaration of the Governing Body: </h4>
                                <ul>
                                    <li>The governing body members of the NGO may need to sign a declaration stating their willingness to serve in their respective positions.  </li>
                                </ul>
                            
                        </div>
                        <div className='content2-c1' >
                            <h2>Steps for NGO Registration: </h2>
                            
                                <p><b>Define Mission and Objectives:  </b>  Clearly outline the NGO's purpose, mission, and the specific social issues it aims to address. </p>
                                <p><b>Select a Suitable Name:  </b> Choose a unique and meaningful name for the NGO, ensuring it aligns with the organization's mission. </p>
                                <p><b>Form a Governing Body:  </b>  Create a governing body or board of trustees that will oversee the NGO's operations and decision-making. </p>
                                <p><b>Draft a Constitution or Bylaws:  </b> Develop a constitution or bylaws that outline the organizational structure, roles of members, and governing processes. </p>
                                <p><b>Legal Structure:  </b> Decide on the type of legal structure for the NGO, such as a trust, society, or a non-profit company, based on the regulations of your country. </p>
                                <p><b>Registration with Relevant Authorities:  </b>  Submit the necessary documents and applications to the appropriate government authority responsible for NGO registrations. </p>
                                <p><b>Bank Account and Funding:  </b> Open a bank account in the NGO's name to handle financial transactions and donations. </p>
                                <p><b>Compliance and Reporting:  </b>  Comply with all legal and regulatory requirements and submit periodic reports as mandated by the authorities. </p>
                                <p><b>Get Tax Exemption Status: </b> If applicable, apply for tax-exempt status to avail of tax benefits for the NGO and its donors. </p>
                               
                                

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is an NGO? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>An NGO (Non-Governmental Organization) is a non-profit, voluntary group or association that works towards addressing social, environmental, and humanitarian issues. NGOs aim to make a positive impact on society and often rely on donations and grants to fund their activities.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Why should I register my NGO? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Registering your NGO provides legal recognition and credibility. It allows your organization to access various benefits, including tax exemptions, fundraising opportunities, and eligibility for government grants. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>What are the basic requirements for NGO registration?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The specific requirements vary by country and region, but generally, you will need a formal constitution or bylaws, a board of members, and a clear mission statement describing your organization's objectives. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>How long does the NGO registration process take? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The registration process duration varies based on the country and its administrative procedures. It can take a few weeks to several months to complete, depending on the complexity and efficiency of the government's registration system.</Typography> 
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> Do NGOs have to pay taxes? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  In many countries, NGOs are eligible for tax exemptions on their income and donations. However, certain rules and criteria need to be met to avail of these benefits. Consult with a legal expert or tax advisor to understand the specific tax regulations for NGOs in your country.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What documents are needed for NGO registration? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Typical documents required include the organization's constitution or bylaws, a list of board members and their credentials, a registration application form, and identification documents of the founding members.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Can an NGO engage in profit-making activities? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  NGOs are primarily focused on non-profit activities to fulfill their mission. Engaging in profit-making activities is generally discouraged, as it may affect the organization's tax-exempt status and its core objectives.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Ngoregistration