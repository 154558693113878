import React  ,{useState ,useEffect}from 'react';
import './gstregistration.scss';

import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Taxationform from '../taxation/taxationform';



const Gstregistration = () => {
    const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset ;

      const sectionElements = document.querySelectorAll('.page_sect_cont');
     
      sectionElements.forEach((section, index) => {
        const { top } = section.getBoundingClientRect();
        if (top <= scrollTop) {
          setActiveSection(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNavigation = (index) => {
    const sectionElements = document.querySelectorAll('.page_sect_cont');
    const section = sectionElements[index];
    section.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div className='gst'>
        <div className='gst-container'>
            <div className='gst-content1' >
                <div className='gst-content1-container'>
                    <div className='content1'>
                        <h1>Online GST Registration @ <br/> Only ₹ 599 With Vanigasevai</h1>
                        <p>GST Registration in 7 business days</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>GST Registration</h3>
                            <Taxationform />
                        </div>

                    </div>

                    <div className='content3'>
                        <h2>List of documents required for GST registration </h2>
                       
                        <div className='content3-row'>
                            <div className='content3-col1'>
                                <h3>Private Limited Company</h3>

                                <ul>
                                <li>Certificate of Incorporation</li>
                                    <li>PAN Card of Company</li>
                                    <li>Articles of Association, AOA</li>
                                    <li>Memorandum of Association, MOA</li>
                                    <li>Resolution signed by board members</li>
                                    <li>Identity and address proof of directors</li>
                                <li>Digital Signature</li>
                                <li>Director's Proof</li>
                                </ul>

                            </div>
                            <div className='content3-col1 '>
                                <h3>LLP</h3>

                                <ul>
                                    <li>PAN Card of LLP</li>
                                    <li>LLP Agreement</li>
                                    <li>Partner's names and address proof</li>
                                    <li>Director's Proof</li>
                                    
                                </ul>

                            </div>
                            <div className='content3-col1'>
                                <h3>Individual/Proprietorship</h3>
 
                                <ul>
                                    <li>PAN Card</li>
                                    <li>Address proof of proprietor</li>
                                    
                                </ul>

                            </div>
                        </div>
                    </div>



                </div>

            </div>
           <div className='gst-content2'>
                <div className='gst-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>

                    </div>
                    
                    
                </div>

            </div>
            <div className='gst-content3'>
                <div className='gst-content3-container'>
                   
                    <div className='content1'>
                        <div className='content1-container'>
                            <ul>
                                <li className={activeSection === 0 ? 'active-side-nav' : '' }  onClick={() => handleNavigation(0)} >Overview as Gst Registration</li>
                                <li className={activeSection === 1 ? 'active-side-nav' : '' }  onClick={() => handleNavigation(1)}>Documents Required</li>
                                <li className={activeSection === 2 ? 'active-side-nav' : '' } onClick={() => handleNavigation(2)}>Registration Process</li>
                                <li className={activeSection === 3 ? 'active-side-nav' : '' }  onClick={() => handleNavigation(3)}>FAQs</li>
                            </ul>
                        </div>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1 page_sect_cont'  >
                            <h2>Overview of GST Registration Online in India</h2>
                            <p>Goods and Services Tax, or GST, is a tax levied on all products and services purchased in India. Taxation, value-added tax, and services tax are some indirect taxes replaced in India by the goods and services tax (GST), which is also an indirect tax. According to the Goods and Service Tax Act, which the Indian Government approved on March 29, 2017, the Goods and Service Tax (GST) has been in effect since July 1, 2017. As per GST jurisdiction, if you do business without GST registration, it is considered an offence.</p>
                        
                        </div>
                        
                        <div className='content2-c1 page_sect_cont' >

                            <h2>Mandatory Documents For Online GST Registration</h2>
                            <p>The list of documents required for registration of GST for various business are as follows:</p>

                            <h3>Private Limited Company</h3>
                                <ul>
                                    <li>Certificate of Incorporation</li>
                                    <li>PAN Card of Company</li>
                                    <li>Articles of Association, AOA</li>
                                    <li>Memorandum of Association, MOA</li>
                                    <li>Resolution signed by board members</li>
                                    <li>Identity and address proof of directors</li>
                                    <li>Digital Signature</li>
                                    <li>Director's Proof</li>
                                </ul>
                            <h3>LLP</h3>
                                <ul>
                                    <li>PAN Card of LLP</li>
                                    <li>LLP Agreement</li>
                                    <li>Partner's names and address proof</li>
                                    <li>Director's Proof</li>
                                </ul>
                            <h3>Individual/Proprietorship</h3>
                                <ul>
                                    <li>PAN Card</li>
                                    <li>Address proof of proprietor</li>
                                    
                                </ul>

                        </div>
                        <div className='content2-c1 page_sect_cont ' >
                            <h2>GST Registration Process</h2>
                            <p></p>
                            <ul>
                                <li>PAN </li>
                                <li>Address </li>
                                    
                            </ul>

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion  page_sect_cont'  >
                            <h2>FAQs on GST Registration </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is GST and its purpose? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  GST is a unified tax system that replaces multiple indirect taxes levied by both the Central and State governments. Under GST, both the Central and State governments share the authority to levy and collect taxes on goods and services.  </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>What is the validity of GST registration?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  GST registration has no expiry date. Hence, a GST registration is valid until it is canceled, surrendered, or suspended. The GST registration for non-resident taxable persons and the casual taxable person has a validity period of ninety days as fixed by the Authorities while issuing the GST registration certificate  </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>When should I obtain GST registration?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  A taxpayer can apply and obtain GST registration within 30 days as required by law to obtain GST registration. You can check the criteria for GST Registration  </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>AccoI am not required by law to obtain GST registration. Can I still obtain GSTIN?rdion</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, any person, though not legally responsible as per the GST Act can get registered for GST voluntarily. After obtaining a GST registration, even those voluntarily registered under the Act must file GST returns or pay a penalty of Rs.200 per day of default. Hence, prior to obtaining GST registration, ensure that you have valid reasons for obtaining GST registration.  </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography>Can a person with no GST registration collect GST?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  No, only persons registered under GST should collect GST from the customers. A person not registered under GST cannot collect GST nor claim the input tax credit on GST paid.  </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>How to download the GST registration certificate?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  After the GST registration is approved, the applicants can download a copy of the GST Registration Certificate from the GST common portal.  </Typography>
                                </AccordionDetails>
                            </Accordion>
                           
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <Taxationform />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Gstregistration