import React from 'react';
import './oneperson.scss';
import Form from '../../form/form';


import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Oneperson = () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='opc'>
        <div className='opc-container'>
            <div className='opc-content1' >
                <div className='opc-content1-container'>
                    <div className='content1'>
                        <h1 className='line'>One Person Company (OPC) Registration in India </h1>
                        <p>Get your application submitted within 10 Days</p>
                        <p>Transparent process, thorough follow up and regular updates</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>One Person Company Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='opc-content2'>
                <div className='opc-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='opc-content3'>
                <div className='opc-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Overview of OPC Registration in India</h2>
                            <p>One Person Company is a private company. It is a company with one director and one shareholder only. Individuals can now avail of limited liability by doing sole proprietorship. In the corporate sector of our country, Person Companies are a shining example of vital development.</p>
                            <p>OPCs can be formed by individuals for any lawful purpose, according to Section 3(1)(c) of the Companies Act, 2013."One Person Company" means only one member of the company, according to Section 2(62).</p>
                            <p>One Person Company is bringing the unstructured Proprietorship Business into the structured version of a private company. OPC opens the path for sole proprietors and start-ups</p>

                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of Registering a One Person Company</h2>
                            <p>Registering a One Person Company (OPC) brings several advantages for entrepreneurs and individuals who want to start their own business. Here are some key benefits of registering an OPC:</p>
                            <p><b>Limited Liability: </b> One of the significant advantages of an OPC is limited liability protection. As a sole owner, your personal assets are separate from your business liabilities. This means that your personal savings, properties, and investments are not at risk if the company faces financial difficulties or legal issues. </p>
                            <p><b>Easy Formation: </b>Easy Formation: Registering an OPC is a relatively simple and streamlined process compared to other forms of business entities. It requires only one director and one nominee, making it a convenient option for solo entrepreneurs who want to start their own venture. </p>
                            <p><b>Complete Control: </b> As the sole director and shareholder of the OPC, you have full control over decision-making and operations. This autonomy allows for quick and efficient decision-making, enabling you to steer the company in the desired direction without any interference. </p>
                            <p><b>Access to Funding: </b> Registering an OPC makes it easier to raise funds and attract investors. It provides a formal structure and legal recognition, which enhances the credibility of the business and increases the chances of securing financing from banks, venture capitalists, or other sources. </p>
                            <p><b>Tax Benefits: </b> OPCs enjoy several tax benefits offered by the government, such as lower tax rates and exemptions. Additionally, an OPC is eligible for certain deductions and incentives provided to small businesses, encouraging growth and development </p>
                            <p><b>Professional Image: </b> Operating as an OPC lends a professional image to your business. It instills trust and confidence in customers, suppliers, and other stakeholders. Having a registered business entity creates a positive perception and may open doors to collaborations and partnerships. </p>
                            <p><b>Ease of Conversion: </b> If your OPC grows and requires additional members or wants to expand its operations, it can be easily converted into a private limited company. This flexibility allows for seamless transitions and scaling of the business. </p>
                            <p><b> </b> </p>
                            <p><b> </b> </p>
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents</h2>
                            <p>If you're planning to register an OPC (One Person Company) in India, you'll need to gather a few essential documents. These documents are necessary for the smooth registration process. Let's take a look at what you'll need:</p>

                            <h4>Identity Proof:  </h4>
                                <ul>
                                    <li> You will need a copy of the identity proof of the proposed director(s) of the OPC. This can be a PAN Card (mandatory for Indian nationals) or Passport (for foreign nationals).</li>
                                </ul>
                            <h4>Address Proof:  </h4>
                                <ul>
                                    <li> You should provide a copy of the address proof of the proposed director(s). This can be a recent utility bill, bank statement, or Aadhaar card (for Indian nationals). For foreign nationals, a utility bill or bank statement from their home country can be submitted.</li>
                                </ul>
                            <h4>Passport-size Photograph:  </h4>
                                <ul>
                                    <li> You need to submit recent passport-size photographs of the proposed director(s) of the OPC.</li>
                                </ul>
                            <h4>Registered Office Proof:  </h4>
                                <ul>
                                    <li> A proof of the registered office address of the OPC is required. This can be a recent utility bill (electricity, water, gas, etc.), a rent agreement, or a sale deed.</li>
                                </ul>
                            <h4>No Objection Certificate (NOC):  </h4>
                                <ul>
                                    <li> If the registered office is owned by someone else, you will need to obtain a No Objection Certificate (NOC) from the owner, along with their identity proof and address proof.</li>
                                </ul>
                            <h4>Memorandum of Association (MOA):  </h4>
                                <ul>
                                    <li> The MOA is a legal document that defines the objectives and scope of the OPC. You will need to draft and submit the MOA.</li>
                                </ul>
                            <h4>Articles of Association (AOA):  </h4>
                                <ul>
                                    <li> The AOA outlines the rules and regulations for the internal management of the OPC. You need to draft and submit the AOA.</li>
                                </ul>
                            <h4>Digital Signature Certificate (DSC):  </h4>
                                <ul>
                                    <li> A digital signature is required for the proposed director(s) of the OPC. It can be obtained from government-approved agencies.</li>
                                </ul>
                            <h4>Director Identification Number (DIN):  </h4>
                                <ul>
                                    <li> Each proposed director must have a DIN issued by the Ministry of Corporate Affairs. You will need to apply for DINs.</li>
                                </ul>
                            <h4>Consent Letters:  </h4>
                                <ul>
                                    <li> All proposed directors must provide their consent to act as directors of the OPC in the prescribed format.</li>
                                </ul>
                                
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>opc Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of opc returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (opc) regulations. Here, we provide a simple breakdown of the various opc return types and their associated deadlines.</p>
                           
                                <p><b>opcR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>opcR-3B:</b>opcR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is a One-Person Company (OPC)? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A One-Person Company is a type of company that can be formed by a single individual, where the individual acts as both the director and shareholder. It provides a legal entity status to the business and offers limited liability protection to the owner.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Who can register a One-Person Company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Any individual who is a resident of India and not a minor can register a One-Person Company. Non-resident Indians (NRIs) and foreign nationals are not eligible to register an OPC. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>How</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>What are the requirements for registering a One-Person Company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>To register a One-Person Company in India, the following requirements must be met:</Typography>
                                    <Typography><ul>
                                        <li>The individual must be a resident of India. </li>
                                        <li>Only one director and one shareholder are allowed. </li>
                                        <li>The director and shareholder can be the same person. </li>
                                        <li>The company must have a unique name that complies with the naming guidelines. </li>
                                        <li>The minimum authorized capital is not required. </li>
                                        
                                    </ul></Typography>
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> How to choose a name for a One-Person Company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  While choosing a name for a One-Person Company, it is important to ensure that the name is unique and does not infringe on any existing trademarks. The name should also adhere to the naming guidelines specified by the Ministry of Corporate Affairs (MCA).</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>How long does it take to register a One-Person Company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The time required for OPC registration can vary depending on the submission of accurate documents and the processing time of the Registrar of Companies (ROC). Generally, it takes around 10-15 days to complete the registration process.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Can a One-Person Company convert into a private or public company? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, a One-Person Company can be converted into a private or public company if it meets the necessary eligibility criteria and follows the conversion process as prescribed by the Companies Act, 2013</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel8a-content" id="panel8a-header" >
                                    <Typography>What are the compliance requirements for a One-Person Company?  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A One-Person Company must comply with certain annual filing and compliance requirements, such as maintaining proper books of accounts, conducting an annual general meeting (AGM), filing annual returns, and submitting audited financial statements. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Oneperson