import React from 'react';
import { useNavigate } from 'react-router-dom';
import './header.scss';

const Header = () => {
  const navigate = useNavigate();

  const goToDetails = (index) => {
    console.log("sf" + index);
    navigate(`/Service-details/${index}`);
  };

  const goToHome = () => {
    navigate('/');
  };


  return (
    <div className="wrapper">
      <nav>
        <input type="checkbox" id="show-search" />
        <input type="checkbox" id="show-menu" />
        <label htmlFor="show-menu" className="menu-icon">
          <i className="fa fa-bars"></i>
        </label>
        <div className="content">
          <div className="logo">
            <a href="/" onClick={goToHome}>
              <img src={require('../assets/img/logo1.png')} alt="vanigasevai logo" />
            </a>
          </div>
          <ul className="links">
          {/* <li><a href="/">Home</a></li>
          <li><a href="./about.html">About</a></li> */}
          <li>
            <a href="/" className="desktop-link">STARTUP</a>
            <input type="checkbox" id="show-features" />
            <label for="show-features">STARTUP</label>
            <ul>
              <li><a href="/private-limited-company ">Private Limited Company  </a></li>
              <li><a href="/limited-liability-partnership">Limited Liability Partnership </a></li>
              <li><a href='/one-person-company'>One Person Company  </a></li>
              <li><a href='/partnership-firm-registration' >Partnership Firm Registration   </a></li>
              <li><a href='/sole-proprietorship-company' >Sole Proprietorship Registration  </a></li>
              <li><a href='/nidhi-company-registration' > Nidhi Company Registration   </a></li>
              
            </ul>
          </li>
          <li>
            <a href="/" className="desktop-link">TAXATION</a>
            <input type="checkbox" id="show-items" />
            <label for="show-items">TAXATION</label>
            <ul>
              <li><a href='/gst-registration' > GST Registration</a></li>
              <li><a href='/gst-returns-filing' >GST Return Filing</a></li>
              <li><a href='/' onClick={() => goToDetails(10)} >GST LUT Filing</a></li>
              <li><a href='/tds-return-filing' >TDS Return Filing</a></li>
              <li><a href='/due-diligence' >Due Diligence  </a></li>
              <li><a href='/professional-tax' > Professional Tax Registration</a></li>
              <li><a href='/income-tax'>Income Tax Return Filing   </a></li>
              
            </ul>
          </li>
          <li>
            <a href="/" className="desktop-link">INTELLECTUAL PROPERTY </a>
            <input type="checkbox" id="show-services" />
            <label for="show-services">INTELLECTUAL PROPERTY </label>
            <ul>
              <li><a href='/ngo-registration' >NGO Registration</a></li>
              <li><a href='/trademark-registration' > Trademark Registration</a></li>
              <li><a href='/copy-right-registration' >Copyright Registration </a></li>
              <li><a href='/patent-registration' >Patent Registration </a></li>
              <li><a href='/80G-12A-registration' >80G and 12A Registration </a></li>
              <li><a href='/' >Section 8 Company Registration </a></li>
              
            </ul>
          </li>
          <li>
            <a href="/" className="desktop-link">COMPLIANCE</a>
            <input type="checkbox" id="show-lists" />
            <label for="show-lists">COMPLIANCE</label>
            <ul>
              <li><a href='/import-export' > Import Export Code  </a></li>
              <li><a href='/annual-compliance' >Annual Compliance for Pvt Ltd</a></li>
              <li><a href='/annual-filling-llp' >Annual Filing for LLP </a></li>
              <li><a href='/event-compliances' >Event Based Compliances in a Company </a></li>
              <li><a href='/change-Object' >Change in Object Clause</a></li>
              <li><a href='/appointment-directors' >Appointment of Directors </a></li>
              <li><a href='/Change-name' >Change in Name Clause</a></li>
              
            </ul>
          </li>
          <li><a href="/about">ABOUT</a></li>
          <li><a href="/contact">CONTACT</a></li>
         
		  </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;