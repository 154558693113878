export const  serviceData=[
    {       
        id:1,
        servicename:"Gst Registration" ,
        price: "599/-" ,
        mprice:"1599/-",
        gst:"707/-",
        save:"1292/- (10%)",
        description:"Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance" ,
        icon: "fa fa-globe ",
        color: "blue",
        sddescription1:"Apply for GST Registration Online in India with Vanigasevai! As per GST legal process, any business operation or any entity with an annual turnover exceeding Rs 40 lakhs must undergo the GST process/procedure in India as a separate taxable provisio.",
        sddescription2:" As per GST jurisdiction, any business operations whose annual turnover exceeds Rs 40 lakhs must register as a separate taxable provision. This procedure is called GST registration. It takes around 2-6 working days to obtain GST registration online in India." ,
        q1:"Overview of GST Registration Online in India ",
        p1:"Goods and Services Tax, or GST, is a tax levied on all products and services purchased in India. Taxation, value-added tax, and services tax are some indirect taxes replaced in India by the goods and services tax (GST), which is also an indirect tax. According to the Goods and Service Tax Act, which the Indian Government approved on March 29, 2017, the Goods and Service Tax (GST) has been in effect since July 1, 2017. As per GST jurisdiction, if you do business without GST registration, it is considered an offence.",
        q2:"GST Registration Online in India or Goods and Service Tax - One Nation One Tax",
        p2:"Being the biggest tax reform in India, GST is levied on goods and services. This includes both Central taxes (CST, Service Tax, Excise Duty) and State taxes (VAT, Entertainment Tax, Luxury Tax, Octroi). This will help the consumer to bear only GST charged by the last dealer in the chain of trade and supply.     As per GST Regime, any company whose annual turnover is more than Rs.40 lakh is required to register as a normal taxable individual. However, there is an exclusion for hill states and the North East, where the annual turnover exceeds Rs.20 lakh for them. As per GST Regime, any company whose annual turnover is more than Rs.40 lakh is required to register as a normal taxable individual. However, there is an exclusion for hill states and the North East, where the annual turnover exceeds Rs.20 lakh for them. Obtaining GST Registration in India is mandatory for all types of businesses whose turnover meets the above-mentioned criteria. If a business entity carries out any business activities without GST registration, it will be conceived as a punishable offense and the business and its owner will be liable to pay heavy penalties and legal issues." ,
    },
    {       
        id:2,
        servicename:"Import & Export code" ,
        price: "2170/-" ,
        mprice:"2500/-" ,
        gst:"1500/-",
        save:"500/- (10%)",
        description:"Apply for IEC registration online Now! And get the 10-digit IEC code. Without IEC, primarily importers merchants cannot import products, and primarily exporters merchants cannot receive benefits from DGFT." ,
        icon: "fa fa-exchange",
        color: "blue",
        sddescription1:"Get your IE Code Registration done online with us today! It is mandatory for all Indian companies that deal or plan to expand their business activities through import and export business operations. In India, the Director-General of Foreign Trade (DGFT), under the Department of Commerce, issues an online computer registration for companies and businesses dealing in imports/exports in the Indian territory1",
        sddescription2:"" ,
        q1:"Overview of IEC Registration Online in India" ,
        p1:"IEC Registration is required for anyone looking to start an import/export business registration in India. Documents required for IEC Registration (Import Export Code). Register import export code (IEC) in India Register for IE Code online in India with us! It is mandatory for all Indian companies that deal with or plan to expand their business activities through import and export business operations. Online IE Code Registration is a mandatory 10-digit identifying license, for companies and businesses that deal with import-export transactions in the Indian Territory issued by The Director-General of Foreign Trade (DGFT), under the Department of Commerce, in India. " ,
        q2:"" ,
        p2:"" ,

        
    },
    {       
        id:3,
        servicename:"Trademark" ,
        price: "2170/-" ,
        mprice:"2500/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:"Get trademark for your brand name or logo with unique identity and secure legal protection within a week! Get trademark registration for any sign you use to identify your business." ,
        icon: "fa fa-user-circle ",
        color: "blue",
        sddescription1:"Trademark registration in India is essential for businesses looking to establish an individual identity on the market. A registered trademark can be a symbol, image, phrase, word, sound, graphic, or color scheme that distinguishes your products or services from competitors. Vanigasevai specialists offer trademark registration services online, making it convenient for businesses to secure their brand's future success. By registering your brand name, you can gain a competitive advantage in the market and protect your distinctive identity. Don't forget to prioritize trademark registration in India and brand name registration in India to safeguard your business.",
        sddescription2:"" ,
        q1:"" ,
        p1:"" ,
        q2:"" ,
        p2:"" ,

    },
    {       
        id:4,
        servicename:"One Person Company" ,
        price: "6920/-" ,
        mprice:"7500/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:"OPC is a business owned by one owner. Before the Companies Act 2013, a company could only be founded with two directors and members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"OPC, or One Person Company, is a business setup owned by a single individual. It was a concept not available until the Companies Act 2013, which allowed businesses to be formed with one director and member. OPCs provide the same benefits as any other company, but with the advantage that all decisions and operations can be carried out by a single individual. This makes OPCs an excellent solution for entrepreneurs who want to start their own businesses without extra help. With the Companies Act 2013, it is now easier than ever to register an OPC and start trading.",
        sddescription2:"" ,
        q1:"" ,
        p1:"" ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:5,
        servicename:"MSME Registration" ,
        price: "909/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:"Registrations for manufacturing or service lines can be obtained through the MSME Act. This registration provides many benefits regarding taxation, business setup, credit facilities, loans, etc." ,
        icon: "fa fa-cubes",
        color: "blue",
        sddescription1:"Registering your small business under Udyog Aadhaar MSME online registration can grant you access to a variety of benefits, including tax exemptions, sector lending, capital investment, and power tariff grants. This is an incredibly valuable opportunity for small businesses, as MSMEs – or Micro, Small, and Medium Enterprises – are a crucial part of India's socio-economic development. It is essential for MSMEs to take advantage of Udyog Aadhaar MSME online registration in order to maximize their potential and to ensure the country's continued progress. This online registration process is fast and efficient, making it easy to access the many benefits that come with it. With the resources provided by Udyog Aadhaar MSME online registration, small businesses in India can thrive and contribute to the nation's continued growth and development.",
        sddescription2:"" 
    },
    {       
        id:6,
        servicename:"FSSAI / Food License" ,
        price: "1970/-" ,
        mprice:"2200/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:"Get FSSAI license Now! All manufacturers, traders, and restaurants operating any food business that includes manufacturing or processing are given a food license or FSSAI license." ,
        icon: "fa fa-facebook",
        color: "blue",
        sddescription1:"Apply for FSSAI License with Food Registration Online Consultant India ! Get real deals and juicy terms. Pay the charge at a reasonable rate. You can obtain your FSSAI license even before you get your food order! The main aim of the Food Safety and Standards Authority of India or FSSAI License Online is to reduce the chances of food adulteration, and substandard or inferior food products, and improve the accountability of makers by granting them a license for food service business.",
        sddescription2:"" ,
        q1:"" ,
        p1:"" ,
        q2:"" ,
        p2:"" ,
        
    },
    {       
        id:7,
        servicename:"LLP Registration" ,
        price: "7120/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:"Get started with LLP registration for your partnership business through Vanigasevai! Limit your liability and face more compliance issues than PLC registration." ,
        icon: "fa fa-file-text-o ",
        color: "blue",
        sddescription1:"LLP registration is a great way to get started with setting up a partnership business. By registering an LLP, you can benefit from limited liability protection, as the liability of all partners is limited to the amount of their capital contributions. As a result, LLP registration is more advantageous than PLC registration, which may expose partners to more liabilities.",
        sddescription2:"Vanigasevai offers the complete solution for LLP registration. We can help you register your business quickly and easily, and ensure that everything is done in compliance with the relevant regulations and laws. Our team is experienced in helping businesses of all sizes to register their LLP, making the process as simple and straightforward as possible." ,
        
    }
    ,
    {       
        id:8,
        servicename:" Pvt Ltd Company" ,
        price: "6843/-" ,
        mprice:"10000/-",
        gst:"1500/-",
        save:"3859/- (39%)",
        description:"Register your Company with Vanigasevai in 7 days at the lowest price + DIN & DSC for 2 Directors + MOA & AOA + ROC Fees, PAN & TAN" ,
        icon: "fa fa-viacoin",
        color: "blue",
        sddescription1:"Vanigasevai is the one-stop destination for all your company registration needs. Our experts will help you get your venture registered in just 7 days at the lowest price. We help you register your company with the Ministry of Corporate Affairs (MCA) and provide you with a Director Identification Number (DIN) and Digital Signature Certificate (DSC) for two directors. We also provide Memorandum of Association (MOA) and Articles of Association (AOA) and register your company with the Registrar of Companies (ROC). Our services include PAN & TAN registration and ROC fees. So, what are you waiting for? Get in touch with us today and register your company with Vanigasevai.",
        sddescription2:"" ,
        
    },
    {       
        id:9,
        servicename:"TDS Return Filling" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" Every individual whose TDS has been deducted can file his/her TDS return in India. Steps to file a TDS return filing include the submission of documents such as TAN (Tax Deduction and Collection Account Number), Permanent Account Number (PAN), the amount deducted, TDS payment, mode of payment, etc." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"Every individual whose TDS has been deducted can file his/her TDS return in India. Steps to file a TDS return filing include the submission of documents such as TAN (Tax Deduction and Collection Account Number), Permanent Account Number (PAN), the amount deducted, TDS payment, mode of payment, etc.",
        sddescription2:"" ,
        q1:"TDS Return Filing Online in India - Overview" ,
        p1:"TDS returns can be submitted by employers or organizations with a valid Tax Collection and Deduction Account Number. Your filed TDS statements should be prepared using the Returns preparation utility. Vanigasevai provides error-free filing solutions for TDS returns, Income Tax Returns. Individuals whose TDS has been deducted can file TDS returns in India. Steps to submit a TDS return filing include the submission of documents such as TAN (Tax Deduction and Collection Account Number), Permanent Account Number (PAN), the amount collected, TDS payment, mode of payment, etc. TDS or Tax Deducted at Source is a type of tax deducted from anyone who pays. This is if the payment is more than the prescribed threshold limit. The Income-tax Department manages and regulates the rate at which TDS filing must be charged. The entity or an individual that withholds a certain amount is named Deductor, and the person whose tax is withheld is named Deduct It is one of the responsibilities of the Deductor or the employee to collect the TDS return utility before making the salary or payment and it is done to deposit it to the government. With Vanigasevai, TDS Return filing becomes a simple process as we have the best experts and need online verification to avoid all types of errors in the TDS return filing process." ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:10,
        servicename:"GST Return Filing " ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" " ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"GST return filing has become more accessible in today's digital age. With online GST return filing, businesses can save time and resources spent on manual filing. GST return filing online is seamless and hassle-free. All you need to do is log into the GST portal and enter your details, including sales and purchase invoices. The system will generate your return. The benefits of filing GST returns online are numerous. It saves time, reduces errors, ensures compliance with regulations, and provides real-time information on taxes payable and credits available. Do you need help filing your GST returns online? Let Vanigasevai assist you! Our team of experts can guide you through the process and ensure your returns are submitted accurately and on time. Don't risk penalties or legal consequences for non-compliance. Contact us now to start your GST return filing.",
        sddescription2:"" ,
        q1:"GST Return Filing Online in India - Overview" ,
        p1:"The GST return filing process is when businesses registered with GST submit details of their sales and purchases of goods and services, along with the amount of tax collected and paid, to the tax authorities on a monthly, quarterly, or annual basis. A comprehensive tax system has been instituted in India as a result of the introduction of the Goods and Services Tax, which enables the government to provide taxpayers with a wide range of services that are aligned with the requirements of the Indian income tax system, including the registration, filing and compliance processes. Vanigasevai offers 100% Online GST Return Filing services in India." ,
        q2:"Who Should File GST Returns in India?" ,
        p2:"" ,
        
        
    },
    {       
        id:11,
        servicename:" GST LUT Filing" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" " ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"The letter of undertaking is a document where the user declares that all GST obligations have been met. This is provided if the IGST is not paid during the export process. Additionally, notification No. 37 /2017 – Central Tax To export products, services, or both without the payment of IGST, you must provide LUT. If the exporter does not supply the LUT, he must pay IGST or post an export bond. Previously, LUTs could only be lodged in person at the appropriate GST office. However, the government has made the LUT filing process more accessible by making it available online",
        sddescription2:"" ,
        q1:"Who can benefit from Letters of Undertaking (LUT) and Bonds?" ,
        p1:"LUTs can be used by any registered taxpayer exporting goods or services. Any person who has been prosecuted under the Act for tax evasion in an amount of Rs. 2.5 crores or more are not eligible for LUTs. The validity of such LUTs is limited to one year (until the end of the financial year). Every financial year, an exporter who provides LUTs must provide updated LUTs. If the LUT conditions are not met within the timeframe, the privileges are removed, and the exporter must post bonds. If the export is made without IGST, bonds should be issued to all other assesses together with those who have been prosecuted for tax 2.5 crores or more under GST legislation). Undertaking letters can be provided and submitted online via the GST portal. At the same time, the bonds must be emailed to the department because a hard copy must be sent to the department. LUT/Bonds can be used in the following transactions:" ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:12,
        servicename:"Due Diligence" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"The due diligence service in India is an investigative process that includes an audit, or a review of an investment opportunity that is performed in order to confirm all the relevant facts that are to be considered. Due diligence reports play a vital role in any type of financial dealings in a business venture, as they are instrumental in scrutinizing the financial records of the opposing party before fixing or signing into any proposed transactions with them.",
        sddescription2:"" ,
        q1:"Overview of Due Diligence" ,
        p1:"Investors, bankers, and acquirers rely on Due Diligence as a standard of care during Mergers and Acquisitions. Due diligence in India is commonly used by investing partners as a pre-investment intelligence tool to obtain an independent and sophisticated report. Due diligence is a verification process and it also includes investigation, or audit of a potential deal or investment aiming to confirm all relevant facts and financial details and to inspect anything brought up at the time of any investment process or an M&A deal. Due diligence is completed before a business deal closes to provide the buyer with assurance of what they’re acquiring. Deals or transactions involving a due diligence process have a high probability of success. Due diligence enhances confidence to make informed decisions by increasing the quality of information available to a company's people or decision-makers." ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:13,
        servicename:"Income Tax Return Filing" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"Filing your income tax return online means submitting it to the Indian government electronically or through a physical document. In this document, you declare your income, deductions, and tax liability for a given financial year. It is a legal requirement for individuals and businesses to file annual income tax returns, and failure to do so may result in penalties. The tax return helps the government determine the tax payable or refundable by a taxpayer based on their income and deductions for that year. The income tax return filing process provides detailed information about your income sources, deductions, and tax-saving investments made during the financial year. The taxpayer's tax liability or refund is then calculated based on this information.",
        sddescription2:"" ,
        q1:"Overview of Income Tax Return Filing India" ,
        p1:"" ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:14,
        servicename:"Company" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"",
        sddescription2:"" ,
        q1:"" ,
        p1:"" ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:15,
        servicename:"Partnership Firm Registration" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"Generally, a Partnership firm is a firm collectively owned by Partners and operates the business. It shares their liabilities and responsibilities with each other based on the terms and conditions of the registered Partnership Deed. Partnership firms are of two types, registered and non-registered firms. It is not mandatory to register but it is highly recommended to register a partnership firm registration in India. This will enable you to enjoy various government benefits.",
        sddescription2:"" ,
        q1:"Partnership Firm Registration Online in India" ,
        p1:"Partnership firms are a business structure well-known among entrepreneurs. This type of business structure is preferred with the mutual consent of all the company partners for a profitable purpose. Generally, the firm is managed, controlled, and owned by a set of people known as partners with some shared capital in the firm. An affiliation of two or more individuals who merge business activities is called a partnership firm. Such an organization aims to profit. Members of this partnership firm are named partners. All the partners or members of the firm share the profits and losses of the firm in proportion to their respective ownership and contribution. In the case of a partnership firm, the amount of money invested is often huge as each partner or member can contribute to the total amount of capital investment required. The decision-making process and procedure of a partnership firm is a corporate or collective business. Partners must travel in the same way before making any business decision.Partnership firms are of two types, registered and unregistered. In India, it is not mandatory to register a partnership firm, but it is highly recommended that you do so. Registered Partnership Firms enjoy various advantages not applicable to Non-Registered Partnership Firms. With very little documentation, rules, and formalities, partnership registration of a firm is done under the Partnership Act, 1932." ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:16,
        servicename:"Sole Proprietorship Registration " ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"Sole proprietorship company registration is one of the simplest businesses to start in India. It is also one of the easiest and most convenient ways to start a business, by registering online. The legal standing of a sole proprietorship business and its owner is the same since the owner's identity is the sole proprietorship's identity. Sole Proprietorship company registration online in India is the easiest form of business to operate since it isn't governed by specific laws and regulations.",
        sddescription2:"" ,
        q1:"What is a Sole Proprietorship Firm?" ,
        p1:"Sole Proprietorship is a business structure where a single individual owns and manages the entire business. It is the oldest and simplest business structure to start in India, and it can be incorporated within 15 days. This makes it one of the most popular business structures among small traders and merchants in the unsystematic sector. A proprietorship company usually does not require registration since it can be distinguished through alternate government-related registrations, such as GST. Moreover, since it doesn't have perpetual existence and unlimited liability, it doesn't require formal registration. Sole Proprietorship Firm Registration is equivalent to individual company registration or One Person Company Registration. Registering Proprietorship Firm in India doesn't require any formalities or registration on paper. You can easily register a sole proprietorship online with the help of Vanigasevai. In a sole proprietorship business structure, the business owner is legally and financially responsible for all debts and loans. The proprietorship firm is not considered a legal entity, and there is no legal difference between the business and the owner. Sole Proprietorship Registration In India is one of the most popular forms of business because of its simplicity and ease of starting up at a nominal cost." ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:17,
        servicename:"Nidhi Company Registration" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"Looking for a way to start your own finance business without investing a lot of capital? If your answer is yes, then the Nidhi company is the most suitable choice. Nidhi Company is one kind of Non-Banking Financial Company (NBFC) which is an excellent choice of company for individuals looking to save money and lead a careful business and life. So, it is always better to register an online Nidhi Company.",
        sddescription2:"" ,
        q1:"Overview of  Nidhi Company Registration" ,
        p1:"Who Should Register For A Nidhi Company? Nidhi companies can be registered for cultivating thrift habits. Nidhi Company Registration is a procedure for incorporation of a NBFC called Nidhi. Know all about Nidhi Registration, documents required for Nidhi Company Registration. Vanigasevai allows you to register a Nidhi company online.Are you preparing to start a finance business without too much capital investing? If your answer is yes, Nidhi Company is the logical choice. Nidhi Company is one kind of Non-Banking Financial Company (NBFC) which is an excellent choice of company for individuals looking to save money and lead a careful business and life. So, it is always better to complete the Nidhi Limited Registration online. Nidhi Company Registration Online in India is an NBFC or Non-banking financing company. It was regulated by the Ministry of Corporate Affairs and registered under Section 406 of the Companies Act, 2013. The RBI monitors all transactions relating to financial matters. Nidhi Company's primary business is to lend money to its core members. The company members or shareholders are asked to save and invest money in the company. These invested deposits are then utilized by the company members or shareholders to grant advances or loans, and to obtain government-issued stocks, debentures, bonds, securities and so on." ,
        q2:"" ,
        p2:"" ,
        
        
    },
    {       
        id:17,
        servicename:"Company" ,
        price: "1200/-" ,
        mprice:"2000/-",
        gst:"1500/-",
        save:"500/- (10%)",
        description:" members." ,
        icon: "fa fa-certificate",
        color: "blue",
        sddescription1:"",
        sddescription2:"" ,
        q1:"" ,
        p1:"" ,
        q2:"" ,
        p2:"" ,
        
        
    }

    
    

    
]