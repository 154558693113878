import React, { useState } from 'react';
import axios from 'axios';
import './startup.scss';

const Startupform  = () => {
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();

		const objt = { name, phone, email};

		axios.post('https://sheet.best/api/sheets/a4436daf-9564-4df3-9c90-c1258e38cc59', objt)
			.then((response) => {
				console.log(response);

				// Clear the input fields
				setName('');
				setPhone('');
				setEmail('');
			});
	};

	return (
		<div>
			<form className="form">
				<input
					placeholder="Enter your Name"
					onChange={(e) => setName(e.target.value)}
					value={name}
				/>
				<input
					placeholder="Enter your Phone"
					onChange={(e) => setPhone(e.target.value)}
					value={phone}
				/>
				<input
					placeholder="Enter your Email"
					onChange={(e) => setEmail(e.target.value)}
					value={email}
				/>
				
				<input className="btn" type="submit" onClick={handleSubmit} value="Submit" />
			</form>
		</div>
	);
}

export default Startupform;