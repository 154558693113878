import React from "react";
import './home.scss'
import Searchbox from "../searchbox/searchbox.js";
import App from '../searchbar/App.js';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

const Home = () =>{

    // var settings = {dots: false,infinite: true,speed: 100,slidesToShow: 1,slidesToScroll: 1,autoplay:true};

    return(
        // <div className="home">
        //     <Slider {...settings}>
        //         <div>
        //             <img src={require('../assets/img/homebackground1.jpg')} alt='no image'/>
        //         </div>
        //         <div>
        //             <img src={require('../assets/img/homebackground.jpg')} alt='no image'/>
        //         </div>
        //         <div>
        //             <img src={require('../assets/img/homebg1.jpg')} alt='no image'/>
        //         </div>
        //     </Slider>
        // </div>

        <div className="home">
            <div className="home-container">
                <div className="home-text">
                    <h1>Legal And Auditing Is Now Online</h1>
                    <p>Business Is Now Simple</p>
                </div>

                <div className="search-box">
                    {/* <Searchbox  /> */}
                    <App />

                </div>
                <div className="popular-search">
                    <span>Popular:</span> <a href="/gst-registration" className="">GST Registration </a>

                    <a href="/private-limited-company" className="">Company Registration </a>
                    <a href="/" className=""> Trademark </a>
                    <a href="/private-limited-company" className="">Private Limited Company </a>
                    <a href="/" className="">FSSAI Registration  </a>
                </div>
                <div className="home-text1">
                    <h1> India`s largest legal services platform</h1>

                    <div className="row">
                        <div className="col">
                            <div className="col1">
                            <i class= 'fa fa-balance-scale'  aria-hidden="true"></i>
                            </div>
                            <div className="col2">
                                <h4>Legal</h4>
                                <p>Your documents, contracts, and registrations handled by expert lawyers.</p>

                            </div>
                        </div>
                        <div className="col">
                            <div className="col1">
                            <i class= 'fa fa-forumbee  '  aria-hidden="true"></i>
                            </div>
                            <div className="col2">
                                <h4>Tax</h4>
                                <p>Filing returns and paying your taxes – made easier for you</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="col1">
                            <i class= 'fa fa-suitcase  '  aria-hidden="true"></i>
                            </div>
                            <div className="col2">
                                <h4>Compliance</h4>
                                <p>Our chartered accountants & company secretaries will keep your books in order.</p>
                            </div>
                        </div>

                    </div>



                </div>

            </div>
        </div>
    );
}

export default Home; 