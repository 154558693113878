import React from 'react';
import './Ourclients.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const OurClients = () => {
  const clientLogos = [
    require('../assets/img/slide-logo1.png'),
    require('../assets/img/slide-logo2.png'),
    require('../assets/img/slide-logo3.png'),
    require('../assets/img/slide-logo4.png'),
    require('../assets/img/slide-logo5.png'),
    require('../assets/img/slide-logo6.png'),
    require('../assets/img/slide-logo7.png'),
    // Add more logo filenames here
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4, // Number of logos visible at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500, 
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='client'>
        <div className='client-container'>
            <div className='client-head'>
                <h1>OUR CLIENTS</h1>
                
            </div>

        <div className="client-carousel">
            <Slider {...settings}>
                {clientLogos.map((logo, index) => (
                  <div  key={index} >
                    <div className="client-logo">
                    <img src={logo} alt={`Client Logo ${index}`} />
                </div>
                  </div>
                
                ))}
            </Slider>
        </div>

        </div>
    </div>
    
  );
};

export default OurClients;
