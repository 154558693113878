import React from 'react';
import './partnership.scss';
import Form from '../../form/form';


import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Partnership = () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='pfr'>
        <div className='pfr-container'>
            <div className='pfr-content1' >
                <div className='pfr-content1-container'>
                    <div className='content1'>
                        <h1 >Partnership Firm Registration </h1>
                        <p>Partnership firm registration deed drafted within 3 days, completely online & hasslefree</p>
                        <p>Transparent process through follow-up and regular updates</p>
                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>Partnership Firm Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='pfr-content2'>
                <div className='pfr-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='pfr-content3'>
                <div className='pfr-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Partnership Firm Registration - An Overview</h2>
                            <p>Partnership firms are one of the most common forms of business entities, characterized by a voluntary association of two or more individuals who come together to carry out a business venture. Registering a partnership firm provides it with legal recognition and ensures a transparent framework for conducting business activities. In this article, we will provide a simple overview of partnership firm registration, outlining the key steps involved and its benefits.</p>
                            <p>The Indian Partnership Act, 1932 regulates the partnership-based entities in India. The involved parties responsible for the formation of a partnership firm are regarded as partners.</p>
                        </div>
                        <div className='content2-c1' >
                           
                            <h2>Types of Partnership Firm In India</h2>
                            <p>In India, there are three common types of partnership firms that individuals can form to conduct business together. These are general partnership, limited partnership, and registered partnership</p>
                            <h3> General Partnership:</h3>
                            <p> A general partnership is the simplest and most common form of partnership. In this type, all partners share equal rights and responsibilities in managing the business. They also share the profits and losses of the partnership equally or as per the agreed-upon ratio. However, each partner is personally liable for the debts and obligations of the partnership.</p>
                            <h3>Limited Partnership:</h3>
                            <p> A limited partnership consists of two types of partners: general partners and limited partners. General partners are responsible for managing the business and have unlimited liability for the partnership's debts. On the other hand, limited partners contribute capital to the partnership but do not actively participate in its management. They have limited liability, which means their liability is restricted to the amount they have invested in the partnership.</p>
                            <h3>Registered Partnership:</h3>
                            <p> A registered partnership is a general partnership that is registered under the Indian Partnership Act, 1932. By registering the partnership, it gains legal recognition, and partners enjoy certain rights and benefits, such as the ability to sue and be sued in the partnership's name. The partnership's existence and details can be verified through the official registration certificate.</p>
                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of pfr Return Filing </h2>
                            <p>Registering a partnership firm brings numerous advantages and legal protections to the table. This simple process can have a profound impact on your business, providing you with a solid foundation for growth and success. Here are some key benefits of partnership firm registration: </p>
                            
                            <p><b>Legal recognition and credibility: </b> By registering your partnership firm, you gain legal recognition as a distinct entity. This enhances your credibility in the eyes of customers, suppliers, and financial institutions. Being a registered entity establishes trust and professionalism, which can help attract more clients and investors. </p>
                            <p><b>Access to funding and credit opportunities: </b> A registered partnership firm gains access to various financial resources, such as bank loans, lines of credit, and other funding options. Financial institutions are more inclined to provide financial assistance to registered entities, as it signifies stability and accountability. This access to funding can fuel the growth and expansion of your business. </p>
                            <p><b>Limited liability protection:  </b>One of the notable advantages of partnership firm registration is the protection of limited liability. As partners, your personal assets are shielded from business liabilities and debts. This means that if the firm faces financial difficulties, your personal assets are generally not at risk, providing you with a sense of security. </p>
                            <p><b>Tax benefits and compliance simplicity: </b> Partnership firms enjoy certain tax benefits and advantages. The firm's profits are taxed at the individual partners' income tax rates, which may result in lower tax liabilities compared to other business structures. Additionally, registered firms have clear tax compliance requirements, making it easier for partners to fulfill their tax obligations. </p>
                            <p><b>Continuity and longevity: </b> Partnership firms have the advantage of perpetual existence, regardless of changes in the composition of partners. This means that the firm can continue its operations even if a partner retires, resigns, or passes away. This stability and continuity allow the business to thrive and grow over the long term. </p>
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents Required for Partnership Firm Registration</h2>
                            <p>When registering a partnership firm, certain documents are typically required to complete the process. These documents help establish the firm's legal existence and provide important information about its partners and operations. Here are the essential documents needed for partnership firm registration: </p>

                            <h4>Partnership Deed: </h4>
                                <ul>
                                    <li> This is a written agreement that outlines the terms and conditions agreed upon by the partners. It includes details such as the name of the firm, the names and addresses of the partners, their capital contributions, profit-sharing ratios, and the duration of the partnership. </li>
                                </ul>
                            <h4>PAN Cards: </h4>
                                <ul>
                                    <li> The partners need to submit their individual Permanent Account Number (PAN) cards issued by the Income Tax Department. PAN cards help identify the partners and track their financial transactions. </li>
                                </ul>
                            <h4>Address Proof: </h4>
                                <ul>
                                    <li> Any valid address proof document, such as Aadhaar card, voter ID card, passport, or utility bills (electricity, water, etc.), should be provided to establish the partners' residential address. </li>
                                </ul>
                            <h4>Identity Proof: </h4>
                                <ul>
                                    <li> Along with the PAN card, partners must submit a valid identity proof document such as Aadhaar card, voter ID card, passport, or driver's license. This ensures that the partners' identities are verified. </li>
                                </ul>
                            <h4>Passport Size Photographs: </h4>
                                <ul>
                                    <li> Recent passport-sized photographs of each partner should be provided to be affixed to the registration documents. </li>
                                </ul>
                            <h4>Registration Application: </h4>
                                <ul>
                                    <li> The partnership firm needs to complete the prescribed registration application form. This form includes details like the firm's name, address, partners' information, and the nature of the business. </li>
                                </ul>
                            <h4>Partnership Firm Name Verification: </h4>
                                <ul>
                                    <li> Partners need to verify the availability and uniqueness of the firm's name through the online portal provided by the Registrar of Companies (ROC). It is advisable to provide multiple options in case the preferred name is already taken. </li>
                                </ul>
                            <h4>Bank Account Proof:  </h4>
                                <ul>
                                    <li>A copy of the firm's bank account statement or canceled cheque is necessary to establish a bank account in the partnership firm's name. </li>
                                </ul>
                            <h4>Optional:</h4>
                                <ul>
                                    <li> Depending on the nature of the business, additional documents may be required. For example, if the firm deals with specific licenses or permits, relevant documents related to those may be needed. </li>
                                </ul>
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>pfr Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of pfr returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (pfr) regulations. Here, we provide a simple breakdown of the various pfr return types and their associated deadlines.</p>
                           
                                <p><b>pfrR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>pfrR-3B:</b>pfrR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is a partnership firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A partnership firm is a type of business structure where two or more individuals come together to carry out a business venture with the objective of making a profit. It is governed by the Indian Partnership Act, 1932.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>What are the essential requirements to form a partnership firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  To form a partnership firm in India, the following requirements must be met: </Typography>
                                    <Typography><ul>
                                        <li>Minimum of two partners, with a maximum of 20 partners in the case of non-banking businesses and 10 partners for banking businesses. </li>
                                        <li>The partners must agree to share the profits and losses of the firm. </li>
                                        <li>There is no requirement for minimum capital investment. </li>
                                        
                                        
                                    </ul></Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>How is a partnership firm registered?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Partnership firms are not mandatory to be registered, but it is advisable to do so. To register a partnership firm, partners need to follow these steps: </Typography>
                                    <Typography><ul>
                                        <li>Choose a suitable name for the partnership firm. </li>
                                        <li>Prepare a partnership deed that includes the terms and conditions of the partnership. </li>
                                        <li>Apply for a partnership firm registration by submitting the application along with the prescribed fee to the Registrar of Firms.</li>
                                        
                                    </ul></Typography>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>Is there a specific format for the partnership deed? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>There is no specific format for the partnership deed, but it should include the following details:</Typography>
                                    <Typography><ul>
                                        <li>Name and address of the partnership firm. </li>
                                        <li>Names and addresses of all partners. </li>
                                        <li>Nature of business activities. </li>
                                        <li>Profit-sharing ratio among the partners. </li>
                                        <li>Duration of the partnership, if any. </li>
                                        <li>Terms and conditions regarding the operation and management of the firm. </li>
                                        
                                    </ul></Typography>
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> What are the tax implications for a partnership firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  In a partnership firm, the partners are taxed individually on their respective shares of profits. The firm itself is not taxed separately. Partners need to file their income tax returns and pay taxes accordingly.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>Can a partnership firm have foreign partners? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, a partnership firm can have foreign partners. However, foreign partners need to comply with the rules and regulations set by the Reserve Bank of India (RBI) and other relevant authorities.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Can a partnership firm be dissolved or reconstituted? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, a partnership firm can be dissolved by mutual consent of the partners or by an order of the court. It can also be reconstituted by adding new partners or removing existing partners, as per the terms mentioned in the partnership deed.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Partnership