import React from 'react';
import './incometax.scss';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Taxationform from '../taxation/taxationform';



const Incometax = () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  
  return (
    <div className='itr'>
        <div className='itr-container'>
            <div className='itr-content1' >
                <div className='itr-content1-container'>
                    <div className='content1'>
                        <h1 >Income Tax Returns Online in India</h1>
                        <h3>Do you worry about the complexities of tax filing?</h3>
                        <p>Let our tax experts take care of it for you! Just share your details with us!</p>
                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>Income Tax Returns </h3>
                            <Taxationform />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='itr-content2'>
                <div className='itr-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='itr-content3'>
                <div className='itr-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Income Tax Returns Online in India - An Overview</h2>
                            <p>Filing income tax returns is an essential obligation for every Indian citizen. To simplify the process and make it more accessible, the Government of India has introduced online filing of income tax returns. This convenient method allows taxpayers to submit their returns from the comfort of their homes or offices, saving time and effort. In this article, we provide an overview of the online income tax return filing process in India.</p>

                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of itr Return Filing </h2>
                            <p> Paying income tax offers several benefits that contribute to the overall well-being of society. Here are some key advantages:</p>
                            <p><b>Public Services and Infrastructure: </b> Income tax revenue is crucial for funding essential public services and infrastructure. These include healthcare, education, transportation, public safety, and more. By paying income tax, you contribute to the development and maintenance of these services, which benefit everyone in the community. </p>
                            <p><b>Social Welfare Programs: </b> Income tax funds various social welfare programs aimed at assisting individuals and families in need. These programs can include unemployment benefits, welfare assistance, food stamps, housing support, and healthcare subsidies. Paying income tax ensures that vulnerable members of society receive the necessary support and care. </p>
                            <p><b>Economic Stability and Growth: </b> Income tax plays a vital role in maintaining economic stability and promoting growth. The revenue generated helps the government invest in infrastructure projects, create job opportunities, and stimulate economic development. By paying your fair share of income tax, you contribute to a robust and thriving economy. </p>
                            <p><b>Public Safety and Defense: </b> Income tax funds the military and other defense forces, ensuring national security and protecting citizens. It also supports law enforcement agencies, firefighters, and emergency services. Paying income tax helps maintain public safety and provides resources for responding to emergencies effectively. </p>
                            <p><b>Education and Skill Development: </b> Income tax revenue is allocated to education systems, including schools, colleges, and universities. This funding helps improve educational facilities, hire qualified teachers, and provide resources for students. By paying income tax, you contribute to the development of a knowledgeable and skilled workforce. </p>
                            <p><b>Healthcare and Research: </b> Income tax plays a significant role in supporting healthcare services and medical research. It helps fund hospitals, clinics, and healthcare programs, making quality medical care accessible to all. Additionally, income tax revenue supports scientific research and advancements in healthcare, contributing to better treatments and innovations. </p>
                            <p><b>Civic Responsibility: </b> Paying income tax is a civic duty that demonstrates your commitment to your country and community. It helps ensure a fair and just society where everyone contributes based on their means. By fulfilling your tax obligations, you participate in the collective effort to build a better future for all. </p>
                            
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents Required for Income Tax Returns File</h2>
                            
                            <h4>Employees: </h4>
                                <ul>
                                    <li>PAN card </li>
                                    <li>Form 16 provided by your employer </li>
                                    <li>Salary slip (**Very essential for ITR filing online)  </li>
                                    
                                </ul>
                            <h4>Business owners, </h4>
                                <ul>
                                    <li>Trading report </li>
                                    <li>Business account details </li>
                                    <li>Profit and loss statement if any  </li>
                                    
                                </ul>
                            <h4>In addition to this, you can submit: </h4>
                                <ul>
                                    <li>Investment proofs </li>
                                    <li>Asset purchase/sale documents </li>
                                    <li>TDS certificates provided by banks  </li>
                                    <li>Interest income statement </li>
                                    <li>Receipts regarding donations from mutual funds and other forms of investment.  </li>
                                    
                                </ul>
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>itr Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of itr returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (itr) regulations. Here, we provide a simple breakdown of the various itr return types and their associated deadlines.</p>
                           
                                <p><b>itrR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>itrR-3B:</b>itrR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on Income Tax Returns </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is an Income Tax Return (ITR)? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>An Income Tax Return is a form that individuals and entities in India use to report their income and taxes paid to the Income Tax Department of India.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Who is required to file an Income Tax Return? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Any individual or entity whose total income exceeds the threshold limit set by the government is required to file an Income Tax Return. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>What is the due date for filing an Income Tax Return?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The due date for filing an Income Tax Return in India is generally July 31st of the assessment year for individuals. However, the government may extend this deadline for specific categories of taxpayers. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>Can I file my Income Tax Return online? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Yes, the Income Tax Department of India provides an online platform called the Income Tax e-Filing Portal where taxpayers can file their returns electronically.</Typography>
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> What documents are required to file an Income Tax Return? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The common documents required for filing an Income Tax Return include PAN (Permanent Account Number), Form 16 (provided by employers), bank statements, investment proofs, and details of any other income sources.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What happens if I miss the deadline for filing my Income Tax Return? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  If you miss the deadline for filing your Income Tax Return, you may be liable to pay a late filing fee and penalties as per the Income Tax Act.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Are there any benefits of filing an Income Tax Return? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, there are several benefits of filing an Income Tax Return. It helps individuals claim tax refunds, apply for loans or visas, carry forward losses, and establish a record of their income and tax compliance.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel8a-content" id="panel8a-header" >
                                    <Typography>Can I revise my Income Tax Return after filing it? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, if you discover any errors or omissions in your filed Income Tax Return, you can file a revised return within a specific time frame. The revised return will replace the original one.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel9a-content" id="panel9a-header" >
                                    <Typography>Do I need to keep a copy of my filed Income Tax Return? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, it is essential to keep a copy of your filed Income Tax Return, along with the supporting documents, for a minimum of six years from the end of the assessment year.</Typography>
                                </AccordionDetails>
                            </Accordion>

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Taxationform/>
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Incometax