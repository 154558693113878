import React from 'react';
import './importexport.scss';
import Form from '../../form/form';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Importexport= () => {

    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='afl'>
        <div className='afl-container'>
            <div className='afl-content1' >
                <div className='afl-content1-container'>
                    <div className='content1'>
                        <h1>Import Export</h1>
                        <p>Make your business global. Apply from anywhere in India with IEC code </p>
                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>Import Export</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='afl-content2'>
                <div className='afl-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='afl-content3'>
                <div className='afl-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                    <div className='content2-c1' >
                            <h2>Import Export - An Overview</h2>
                            <p>An Import Export Code, commonly known as IEC, is a unique 10-digit code issued by the Directorate General of Foreign Trade (DGFT), a division of the Ministry of Commerce and Industry in many countries. It serves as a key identification for businesses involved in cross-border trade activities. Whether you're importing goods to your country or exporting them to international markets, an IEC is a mandatory prerequisite.</p>

                        </div>
                        <div className='content2-c1' >
                            <h2>Key Benefits of Obtaining an IEC: </h2>
                            <p><b>Global Market Access: </b> An IEC opens the doors to global markets. It allows businesses to engage in cross-border trade, connecting them to a vast network of international suppliers, manufacturers, and distributors.</p>
                            <p><b>Enhanced Credibility:  </b> Possessing an IEC enhances your business's credibility in the eyes of international partners and clients. It demonstrates your commitment to complying with international trade regulations. </p>
                            <p><b>Expanded Customer Base: </b> With an IEC, businesses can tap into a broader customer base beyond domestic boundaries. Selling products or services in diverse markets can lead to increased revenue streams. </p>
                            <p><b>Diversification and Risk Management:  </b>Relying solely on the domestic market can expose businesses to economic fluctuations. Diversifying through international trade can mitigate risks and provide stability. </p>
                            <p><b>Access to Government Benefits:  </b>Many government schemes, incentives, and benefits are exclusively available to businesses with a valid IEC. These can range from financial incentives to reduced customs duty rates. </p>
                            <p><b>Opportunity for Growth:  </b>International trade can significantly boost a company's growth trajectory. By reaching new markets, businesses can capitalize on untapped demand and drive expansion. </p>
                            <p><b>Innovation and Competitiveness: </b> Exposure to international markets encourages businesses to innovate their products, services, and processes to match global standards. This, in turn, enhances their competitiveness. </p>
                            <p><b>Learning and Development:  </b>Engaging in international trade exposes businesses to diverse cultures, business practices, and consumer preferences. This learning experience can be invaluable for overall business developmen </p>
                            
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents for IEC Registration:</h2>
                            <p> </p>

                            <h3>PAN Card:  </h3>
                                <ul>
                                    <li>Your Permanent Account Number (PAN) card is a primary identifier for tax purposes and is crucial for IEC registration.</li>
                                                                        
                                </ul>
                            <h3>Identity Proof: </h3>
                                <ul>
                                    <li>Provide a copy of your passport, voter ID, Aadhar card, or driver's license to verify your identity. </li>
                                </ul>
                            <h3>Address Proof: </h3>
                                <ul>
                                    <li>Submit documents such as your passport, utility bills, or rent agreement to validate your business address. </li>
                                </ul>
                            <h3>Bank Account Details:  </h3>
                                <ul>
                                    <li>Furnish a canceled cheque or a bank certificate to establish your business's bank account. </li>
                                </ul>
                            <h3>Business Entity Proof:  </h3>
                                <ul>
                                    <li>Depending on your business type (proprietary, partnership, company, LLP), you'll need to provide relevant registration documents. </li>
                                </ul>
                            <h3>Digital Passport-sized Photographs: </h3>
                                <ul>
                                    <li> Include a few recent passport-sized photographs to accompany your application. </li>
                                </ul>
                            <h3>Letter of Authorization:  </h3>
                                <ul>
                                    <li> If a representative is submitting the application on your behalf, a letter of authorization is required. </li>
                                </ul>
                            <h3> Additional Requirements for Companies:</h3>
                            <h3> Certificate of Incorporation:</h3>
                                <ul>
                                    <li> A copy of your company's Certificate of Incorporation issued by the Registrar of Companies. </li>
                                </ul>
                            <h3>Memorandum of Association (MOA) and Articles of Association (AOA):  </h3>
                                <ul>
                                    <li> A copy of your company's Certificate of Incorporation issued by the Registrar of Companies. </li>
                                </ul>
                            <h3>Board Resolution: </h3>
                                <ul>
                                    <li>A resolution authorizing the person applying for IEC registration on behalf of the company. </li>
                                </ul>
                            

                        </div>
                        {/* <div className='content2-c1' >
                            <h2>afl Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of afl returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (afl) regulations. Here, we provide a simple breakdown of the various afl return types and their associated deadlines.</p>
                           
                                <p><b>aflR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>aflR-3B:</b>aflR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div> */}
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on Import Export Code  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography> What is an Import Export Code (IEC)? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>An Import Export Code (IEC) is a unique 10-digit code issued by the Directorate General of Foreign Trade (DGFT), a department under the Ministry of Commerce and Industry in India. It's a mandatory requirement for any person or business looking to engage in import or export activities.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography> Why is an IEC important? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  IEC is essential as it acts as a primary identification for a business involved in international trade. It's used by customs authorities to monitor and regulate imports and exports, facilitating smooth cross-border transactions. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>Who needs to obtain an IEC?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Any individual, partnership firm, limited liability partnership (LLP), company, or any other entity that wants to engage in importing or exporting goods and services from India needs to obtain an IEC. It's not required for individuals or businesses engaged in activities that are covered under the exempted categories. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>How do I apply for an IEC? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>You can apply for an IEC online through the DGFT portal. The application requires necessary business details, a bank account, and the submission of required documents. Once approved, you will receive the 10-digit IEC code.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography>  Can IEC be used for multiple branches or businesses? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, a single IEC can be used for all branches or business units of the entity. It eliminates the need for separate codes for each branch.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What documents are needed for IEC registration? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The documents generally required for IEC registration include a copy of the PAN card, passport-sized photographs, proof of address, and bank account details. The specific documents might vary based on the type of entity applying.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Is IEC a one-time registration? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, IEC is a one-time registration. Once you obtain an IEC, it's valid for a lifetime and doesn't require renewal.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Import Export</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Importexport