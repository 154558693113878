import React from 'react';
import "./Founder.scss";

const Founder = () => {
  return (
    <div className='founder'>
        <div className='founder-head'>
            <h2>Meet Our CEO</h2>
        </div>
        <div className='founder-container'>
            <div className='founder-img'>
                <img src={require('../assets/img/founder.jpeg')} alt='no image'/>
            </div>
            <div className='founder-text'>
                <div className='details'>
                    <p><span> Name :</span>EALIM NESA GIFTSON I </p>
                    <p><span> Profile :</span>Founder of Vanigasevai </p>
                    <p><span> Email :</span> ealim.001@gmail.com </p>
                    <p><span> Phone :</span>+91 81444 14507 </p>
                
                </div>
                
                <div className='social-media'>
                    <p>Follow Me On More</p>
                    <div className='sicons'>
                        <a href='https://wa.me/+918144414507'><i class="fa fa-whatsapp whats"></i> </a>
                        <a href='https://www.instagram.com/___g_i_f_y_y____/?igshid=MzRlODBiNWFlZA%3D%3D'><i class="fa fa-instagram insta"></i> </a>
                        <a href=''><i class="fa fa-linkedin linkd"></i> </a>
                        
                    </div>
                    
                </div>


                
            </div>
        </div>

    </div>
  )
}

export default Founder