import React from 'react';
import './gstreturnsfilling.scss';
import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Taxationform from '../taxation/taxationform';



const Gstreturnsfiling = () => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


  return (
    <div className='gst'>
        <div className='gst-container'>
            <div className='gst-content1' >
                <div className='gst-content1-container'>
                    <div className='content1'>
                        <h1 className='line'>GST Returns Filing </h1>
                        <p>GST Returns Filing in 7 business days</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>GST Returns Filing</h3>
                            <Taxationform />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='gst-content2'>
                <div className='gst-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='gst-content3'>
                <div className='gst-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>GST Return Filing - An Overview</h2>
                            <p>The GST return filing process is when businesses registered with GST submit details of their sales and purchases of goods and services, along with the amount of tax collected and paid, to the tax authorities on a monthly, quarterly, or annual basis. A comprehensive tax system has been instituted in India as a result of the introduction of the Goods and Services Tax, which enables the government to provide taxpayers with a wide range of services that are aligned with the requirements of the Indian income tax system, including the registration, filing and compliance processes. Vanigasevai offers 100% Online GST Return Filing services in India.</p>

                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of GST Return Filing </h2>
                            <p> All businesses registered under the GST Act must file GST returns. This includes individuals, proprietorships, partnerships, companies, and any other type of legal entity engaged in the supply of goods or services. Regardless of turnover or profitability, if a business is registered under GST, it must file returns.</p>
                            <p>Small businesses must file GST returns if their sales exceed the threshold limit. As of September 2021, businesses with an annual turnover of less than ₹40 lakhs (₹10 lakhs for special category states) are exempt from GST registration and filing returns. Filing returns becomes mandatory if the turnover exceeds the threshold limit.</p>
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents Required for GST Return Filing</h2>
                            <p>GST return filing can be done through the GST portal. The following documents are essential for the GST return filing process:</p>

                                <ul>
                                    <li>Customer GSTIN</li>
                                    <li>Type of Invoice</li>
                                    <li>Place of Supply</li>
                                    <li>List of Invoices including B2B & B2C Services</li>
                                    <li>Invoice Number</li>
                                    <li>GST Rate</li>
                                    <li>Total Amount of SGST, IGST, CGST, and GST Cess that is applicable</li>
                                    <li>Total Amount of SGST, IGST, CGST, and GST Cess that is applicable</li>
                                    <li>Combined Intrastate and Interstate Sales</li>
                                    <li>Taxable Value</li>
                                    <li>HSN-wise Summary Details</li>

                                </ul>
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>GST Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of GST returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (GST) regulations. Here, we provide a simple breakdown of the various GST return types and their associated deadlines.</p>
                           
                                <p><b>GSTR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>GSTR-3B:</b>GSTR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                <p><b>GSTR-4:</b>Designed for composition scheme taxpayers, GSTR-4 is a quarterly return that summarizes the details of inward and outward supplies. It must be filed by the 18th of the month following the end of the quarter. </p>
                                <p><b>GSTR-5: </b>GSTR-5 is meant for non-resident taxable persons who engage in business transactions in India. It needs to be filed within 20 days from the end of the month or the duration of the business transaction, whichever is earlier. </p>
                                <p><b>GSTR-6: </b>  GSTR-6 is filed by Input Service Distributors (ISDs) to provide details of the invoices received and distributed during a particular tax period. It must be filed by the 13th of the following month.</p>
                                <p><b>GSTR-7: </b> GSTR-7 is for businesses required to deduct tax at source (TDS). It involves furnishing details of TDS deductions and needs to be filed by the 10th of the following month </p>
                                <p><b>GSTR-8: </b> This return is for e-commerce operators who need to collect tax at source (TCS). It must be filed by the 10th of the following month. </p>
                                <p><b>GSTR-9: </b>GSTR-9 is an annual return that provides a consolidated summary of all transactions undertaken during the financial year. It must be filed by the 31st of December of the subsequent financial year. </p>
                                <p><b>GSTR-10: </b> GSTR-10 is the final return that needs to be filed by businesses whose GST registration has been canceled or surrendered. It must be filed within three months of the date of cancellation or surrender. </p>
                                <p><b>GSTR-11: </b> GSTR-11 is for taxpayers who have been issued a Unique Identity Number (UIN). It requires the details of inward supplies received during the month and must be filed by the 28th of the following month. </p>
                                <p> </p>
                                <p> </p>
                             

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on GST Returns Filing </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is GST returns filing?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>GST returns filing refers to the process of submitting the necessary documents and information to the tax authorities regarding your business's sales, purchases, and other financial activities. It is a mandatory requirement for businesses registered under the Goods and Services Tax (GST) system.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Who needs to file GST returns?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Any business or individual registered under the GST system needs to file GST returns. This includes businesses with an annual turnover above the threshold set by the government. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>How often do I need to file GST returns?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The frequency of GST returns filing depends on the type of taxpayer you are. Regular taxpayers need to file monthly returns, while composition scheme taxpayers file quarterly returns.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>What documents do I need to file GST returns?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>You will typically need the following documents to file GST returns: </Typography>
                                    <Typography><ul>
                                        <li>Sales and purchase invoices</li>
                                        <li>Details of outward and inward supplies </li>
                                        <li>Input tax credit details </li>
                                        <li>Bank statements </li>
                                        <li>Any other supporting documents as required by the tax authorities </li>
                                    
                                    </ul></Typography>
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> Can I file GST returns online?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, GST returns can be filed online through the GST portal provided by the government. The online system makes it convenient and efficient to submit your returns.  </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What are the consequences of not filing GST returns?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Failing to file GST returns can result in penalties and fines imposed by the tax authorities. It's important to ensure timely and accurate filing to avoid any legal issues. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Do I need professional help to file GST returns?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  While it's not mandatory to seek professional help, it can be beneficial to consult with a tax professional or an accountant who is well-versed in GST regulations. They can assist you in ensuring accurate filing and compliance with the GST laws  </Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>GST Returns Filing</h3>
                            <Taxationform />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Gstreturnsfiling