import React from 'react';
import './soleproprietorship.scss';
import Form from '../../form/form';


import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Soleproprietorship= () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='spr'>
        <div className='spr-container'>
            <div className='spr-content1' >
                <div className='spr-content1-container'>
                    <div className='content1'>
                        <h1 className='line'>Sole Proprietorship Registration </h1>
                        <p>Fastest registration turnaround time in India with Guaranteed document upload to the government portal under 7 days or get a full refund</p>
                        <p>Transaparent process, thorough follow up and regular updates</p>
                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>Sole Proprietorship Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='spr-content2'>
                <div className='spr-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='spr-content3'>
                <div className='spr-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Sole Proprietorship Registration</h2>
                            <p>Sole Proprietorship is a business structure where a single individual owns and manages the entire business. It is the oldest and simplest business structure to start in India, and it can be incorporated within 15 days. This makes it one of the most popular business structures among small traders and merchants in the unsystematic sector. A proprietorship company usually does not require registration since it can be distinguished through alternate government-related registrations, such as GST. Moreover, since it doesn't have perpetual existence and unlimited liability, it doesn't require formal registration.</p>
                            <p> Sole Proprietorship Firm Registration is equivalent to individual company registration or One Person Company Registration. Registering Proprietorship Firm in India doesn't require any formalities or registration on paper. You can easily register a sole proprietorship online with the help of Vanigasevai. In a sole proprietorship business structure, the business owner is legally and financially responsible for all debts and loans. The proprietorship firm is not considered a legal entity, and there is no legal difference between the business and the owner. Sole Proprietorship Registration In India is one of the most popular forms of business because of its simplicity and ease of starting up at a nominal cost.</p>

                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of Sole Proprietorship Firm</h2>
                            
                            <p>A sole proprietorship firm is a simple and popular business structure where a single individual owns and operates the business. There are several benefits associated with this type of business setup: </p>
                            <p><b>Ease of Formation: </b> Establishing a sole proprietorship is relatively easy and inexpensive compared to other business structures. It involves fewer legal formalities and paperwork, making it a straightforward option for individuals starting a small business. </p>
                            <p><b>Full Control: </b> As the sole owner, you have complete control and decision-making power over the business. You can make quick decisions and implement changes without consulting anyone else, allowing for greater flexibility and agility.</p>
                            <p><b>Simplicity of Management: </b> Running a sole proprietorship is uncomplicated. You don't need to hold formal meetings or comply with complex corporate governance rules. This simplicity translates into reduced administrative burden and less time spent on formalities, enabling you to focus on core business operations. </p>
                            <p><b>Direct Profits: </b> As the sole proprietor, you are entitled to all the profits generated by the business. There is no need to share the earnings with partners or shareholders, allowing you to retain and reinvest the profits as you see fit. </p>
                            <p><b>Tax Advantages:  </b> Sole proprietorships benefit from certain tax advantages. The business income is reported on your personal income tax return, eliminating the need for separate corporate tax filings. Moreover, you may be eligible for various tax deductions and credits that can help reduce your overall tax liability. </p>
                            <p><b>Flexibility: </b> Sole proprietorships provide significant flexibility in terms of business operations and decision-making. You can easily adapt to market changes, modify your business strategies, and explore new opportunities without the need for extensive consultation or formalities. </p>
                            <p><b>Quick Decision-Making: </b> With no partners or board of directors, you can make decisions swiftly. This agility can be beneficial when responding to customer needs, market trends, or emerging opportunities, giving you a competitive edge. </p>
                            <p><b> </b> </p>
                            <p><b> </b> </p>
                            
                            
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents For Sole Proprietorship Company Registration </h2>
                            <p>spr </p>

                            <h4>Business Name Registration:</h4>
                                <ul>
                                    <li>  You'll need to choose a unique name for your business and register it with the appropriate government authority. This process may involve filling out a form and paying a nominal fee.</li>
                                </ul>
                            <h4>Identity Proof:</h4>
                                <ul>
                                    <li>  As the sole proprietor, you'll need to provide proof of your identity, such as a valid passport, driver's license, or national identification card.</li>
                                </ul>
                            <h4> Address Proof:</h4>
                                <ul>
                                    <li>  Proof of your business address can be provided by a utility bill, lease agreement, or any other official document indicating your business location. </li>
                                </ul>
                            <h4>Tax Registration:</h4>
                                <ul>
                                    <li>  Depending on your jurisdiction, you may need to register for a tax identification number or obtain a tax certificate. This will enable you to fulfill your tax obligations as a sole proprietor.</li>
                                </ul>
                            <h4>Permits and Licenses:</h4>
                                <ul>
                                    <li>  Certain businesses require specific permits or licenses to operate legally. Research the regulations in your industry and locality to determine if any additional permits are needed.</li>
                                </ul>
                            <h4>Bank Account Details:</h4>
                                <ul>
                                    <li>  To separate your personal and business finances, you'll need to open a business bank account. Provide the necessary information, such as your business name, address, and identification documents, to set up the account.</li>
                                </ul>
                            

                        </div>
                        {/* <div className='content2-c1' >
                            <h2>spr Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of spr returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (spr) regulations. Here, we provide a simple breakdown of the various spr return types and their associated deadlines.</p>
                           
                                <p><b>sprR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>sprR-3B:</b>sprR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div> */}
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs on Sole Proprietorship Firm Registration  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is a sole proprietorship firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A sole proprietorship firm is a business structure where a single individual owns and operates the business. It is the simplest form of business entity and does not require any legal formalities for registration.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>How do I register a sole proprietorship firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Registration of a sole proprietorship firm is not mandatory in most countries. However, you may need to obtain certain licenses or permits depending on the nature of your business. It is advisable to consult local authorities or a legal professional to understand the specific requirements in your jurisdiction. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>Are there any advantages to registering a sole proprietorship firm?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The main advantage of registering a sole proprietorship firm is that it provides a legal identity to your business. This can be helpful while dealing with banks, suppliers, or other entities that require proof of your business existence. Additionally, registering may also allow you to avail certain tax benefits or deductions, depending on local regulations. </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>Is there a difference between a registered and unregistered sole proprietorship firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>An unregistered sole proprietorship firm operates without any legal registration. While it is not mandatory to register, doing so provides legal recognition to your business. A registered sole proprietorship firm enjoys certain advantages such as credibility, access to loans, and legal protection in case of disputes.</Typography>
                                    
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> What documents are required for sole proprietorship firm registration? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The specific documents required for registration may vary depending on the jurisdiction. Generally, you will need to provide identification proof, address proof, and any licenses or permits applicable to your business. It is recommended to consult local authorities or a legal professional to ensure you have the necessary documents.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>Can I convert my sole proprietorship firm into another business structure in the future? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, it is possible to convert your sole proprietorship firm into another business structure, such as a partnership or a private limited company, in the future. The conversion process and requirements may differ based on local regulations. It is advisable to seek professional guidance to understand the procedure and implications of such a conversion.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Do I need to maintain separate financial records for my sole proprietorship firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  While it is not legally required to maintain separate financial records for a sole proprietorship firm, it is highly recommended to do so. Keeping separate records helps in managing your business finances, tracking income and expenses, and simplifying tax filings. It also demonstrates professionalism and facilitates better decision-making.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel8a-content" id="panel8a-header" >
                                    <Typography>Can I hire employees for my sole proprietorship firm? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, as a sole proprietor, you have the flexibility to hire employees for your business. However, you must comply with labor laws, tax regulations, and other employment-related obligations in your jurisdiction. It is important to understand and fulfill your responsibilities as an employer.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel9a-content" id="panel9a-header" >
                                    <Typography>Can I operate a sole proprietorship firm under a different name? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  In most jurisdictions, you can operate a sole proprietorship firm under a business name different from your personal name. This is known as a "Doing Business As" (DBA) or a fictitious name. However, there may be specific rules or registration requirements for using a DBA. It is advisable to check with local authorities or a legal professional before using a different name for your business.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>Private Limited</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Soleproprietorship