import React from 'react';
import './signup.scss';


const Signup = () => {


  return (
    <div className='signup'>
        <div className='signup-container'>
            <div className='signup-img'>
              <img src={require('../assets/img/signup.jpg')} alt='no image'/>


            </div>
            <div className='signup-form'>

                <div className='form-data'>
                    <form>
                        <div className='form-head'>
                            <h2>SIGN UP</h2>
                         </div>
                        <div className='input1' >
                            <i class= 'fa fa-user '  aria-hidden="true"></i>
                            <input type="text" name="user-name" placeholder="User Name"  />
                        
                       
                        </div>
                        <div className='input1' >
                            <i class= 'fa fa-envelope '  aria-hidden="true"></i>
                            <input type="email" required name="email" placeholder="Email"  />
                        
                       
                        </div>
                        <div className='input1' >
                            <i class= 'fa fa-phone '  aria-hidden="true"></i>
                            <input type="number" name="mobile" placeholder="Mobile"  />
                       
                       
                        </div>
                        <div className='input1' >
                            <i class= 'fa fa-lock '  aria-hidden="true"></i>
                            <input type="password" name="new-password" placeholder="New Password" />
                        
                        
                        </div>
                        <div className='input1' >
                            <i class= 'fa fa-lock '  aria-hidden="true"></i>
                            <input type="password" name="confirm-password" placeholder="Confirm password" />
                        
                       
                        </div>
                        <div className='btn' >
                            <button >Signup</button>
                            
                        </div>
                        <div className='link'>
                            <a href='/login'> Already have an account?</a>

                        </div>


                    </form>
                </div>


            </div>
        </div>
    </div>
  )
}

export default Signup