import React from 'react';
import './limitedliability.scss';
import Form from '../../form/form';


import { Accordion ,AccordionSummary,AccordionDetails}   from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Limitedliability = () => {




    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='llp'>
        <div className='llp-container'>
            <div className='llp-content1' >
                <div className='llp-content1-container'>
                    <div className='content1'>
                        <h1 >Apply LLP Registration Online in India</h1>
                        <p>Application submission Guaranteed within 10 days, completely online & hasslefree</p>
                        <p>Transparent process through follow-up and regular updates</p>

                        

                    </div>
                    <div className='content2'>

                        <div className='form-box'>
                            <h3>LLP Registration</h3>
                            <Form />
                        </div>

                    </div>

                    



                </div>

            </div>
           <div className='llp-content2'>
                <div className='llp-content2-container'>
                   
                    <div className='content1'>

                    </div>
                    
                </div>

            </div>
            <div className='llp-content3'>
                <div className='llp-content3-container'>
                   
                    <div className='content1'>

                    </div>
                    <div className='content2'>
                        <div className='content2-c1' >
                            <h2>Limited Liability Partnership Registration Online - An Overview</h2>
                            <p>Limited Liability Partnerships (LLPs) are partnerships in which some or all partners (depending on the jurisdiction) have limited liability. It, therefore, exhibits elements of partnerships and corporations. In an LLP, one partner is not responsible or liable for another partner’s misconduct or negligence. This is a significant difference from an unlimited partnership. In an LLP, some partners have limited liability similar to shareholders of a corporation. A limited liability partnership (LLP) must also have at least one "General Partner" with unlimited liability in some countries. </p>
                            <p>Indian law that governs limited liability partnerships was published in the India Gazette on January 9, 2009. It became effective on 31 March 2009. The first LLP was incorporated in April 2009. Some sections relating to the conversion of existing partnership firms and private and public unlisted companies into LLPs were brought into force on 31-5-2009. At present, there are about 10,000 LLPs formed and registered under the Limited Liability Partnership Act. </p>


                        </div>
                        <div className='content2-c1' >
                            <h2>Benefits of an Limited Liability Partnership</h2>
                            <p>A Limited Liability Partnership (LLP) offers several benefits when it comes to creating copyright-free content for a website. Here are some of the advantages:</p>
                            <p><b>Liability protection: </b> One of the main benefits of an LLP is that it provides liability protection for its partners. This means that if any legal issues arise from the content published on the website, the individual partners' personal assets are protected. They are only held liable to the extent of their investment in the LLP. </p>
                            <p><b>Flexibility and control: </b> LLPs offer flexibility in terms of management and control. Partners can divide responsibilities and make decisions collectively, ensuring that the content creation process is streamlined and efficient. It allows for collaboration and shared decision-making, leading to a better end product. </p>
                            <p><b>Tax benefits: </b>  LLPs often enjoy favorable tax treatment. The profits generated from the website can be distributed among the partners as income, and they can be taxed at their individual tax rates. This can result in potential tax savings compared to other business structures.</p>
                            <p><b>Separate legal entity: </b> An LLP is considered a separate legal entity from its partners. This means that it can enter into contracts, own property, and sue or be sued in its own name. Having a separate legal entity can enhance the credibility and professionalism of the website, which can attract more visitors and opportunities. </p>
                            <p><b> Continuity and succession: </b> LLPs offer continuity and ease of succession planning. Even if one or more partners leave or pass away, the LLP can continue to exist and operate. This ensures that the website can continue to provide valuable content to its audience without significant disruption.</p>
                          
                        </div>
                        <div className='content2-c1' >

                            <h2>Documents Required for LLP Registration</h2>
                            <p>llp </p>

                                <ul>
                                    <li>Customer llpIN</li>
                                    <li>Type of Invoice</li>
                                    <li>Place of Supply</li>
                                    
                                </ul>
                            

                        </div>
                        <div className='content2-c1' >
                            <h2>llp Returns: Types and Due Dates</h2>
                            <p>Understanding the different types of llp returns and their respective due dates is crucial for businesses to ensure compliance with the Goods and Services Tax (llp) regulations. Here, we provide a simple breakdown of the various llp return types and their associated deadlines.</p>
                           
                                <p><b>llpR-1:</b> This return requires businesses to provide details of their outward supplies of goods and services. It needs to be filed by the 11th of the following month. </p>
                                <p><b>llpR-3B:</b>llpR-3B is a summary return that includes the summary of inward and outward supplies, as well as the payment of taxes. It must be filed by the 20th of the following month. </p>
                                

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-c1' >

                        </div>
                        <div className='content2-accordion' >
                            <h2>FAQs  </h2>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel1a-content" id="panel1a-header" >
                                    <Typography>What is an LLP? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>A Limited Liability Partnership (LLP) is a type of business entity that combines the advantages of both a partnership and a company. It provides limited liability to its partners and allows flexibility in managing the business.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel2a-content" id="panel2a-header" >
                                    <Typography>Why should I register my LLP online? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Online registration offers a convenient and efficient way to establish your LLP. It saves time, reduces paperwork, and allows for quick processing and communication with the government authorities. </Typography>
                                
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel3a-content" id="panel3a-header" >
                                    <Typography>What are the essential documents required for LLP registration?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <   Typography>The documents required for LLP registration include:</Typography>
                                    <Typography><ul>
                                        <li>Identity proof (PAN card, passport, or Aadhaar card) of the designated partners. </li>
                                        <li>Address proof (utility bill, bank statement, or rental agreement) of the designated partners. </li>
                                        <li> Digital Signature Certificates (DSC) of the designated partners. </li>
                                        <li>Consent to act as partners and declaration of LLP compliance. </li>
                                        <li>LLP agreement. </li>
                                        
                                    </ul></Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel4a-content" id="panel4a-header" >
                                    <Typography>How long does the online LLP registration process take? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>The online LLP registration process typically takes around 15-20 working days, provided all the documents and information are accurate and complete</Typography>
                                  
                               
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel5a-content" id="panel5a-header" >
                                    <Typography> Can I make changes to my LLP's details after registration? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, you can make changes to your LLP's details after registration. The MCA provides a facility to file various forms for making changes such as alteration of LLP agreement, change in partners, change in address, etc.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel6a-content" id="panel6a-header" >
                                    <Typography>What are the fees involved in online LLP registration? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  The fees for online LLP registration depend on the authorized capital of the LLP. The government charges fees for name reservation, incorporation, and stamp duty, which can vary based on the LLP's capital and state of registration.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}   aria-controls="panel7a-content" id="panel7a-header" >
                                    <Typography>Is it mandatory to have a registered office address for an LLP? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>  Yes, it is mandatory for an LLP to have a registered office address in India. The registered office is the official address of the LLP where all communications, notices, and legal documents will be sent. During the online LLP registration process, you need to provide the address proof for the registered office, such as a utility bill, lease agreement, or ownership documents. It is important to ensure that the registered office address is valid and accessible for receiving official correspondence related to the LLP.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            

                        </div>

                    </div>
                    <div className='content3'>

                        <div className='form-box'>
                            <h3>LLP Registration</h3>
                            <Form />
                        </div>

                    </div>
                    
                    
                </div>

            </div>



        </div>

    </div>
  )
}

export default Limitedliability