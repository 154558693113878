import React from 'react';
import './login.scss';


const Login = () => {


  return (
    <div className='login'>
        <div className='login-container'>
            <div className='login-img'>
              <img src={require('../assets/img/login.jpg')} alt='no image'/>


            </div>
            <div className='login-form'>
                

                <div className='form-data'>
                    <form>
                         <div className='form-head'>
                            <h2>LOGIN</h2>
                         </div>
                        
                        <div className='input1' >
                            <i class= 'fa fa-envelope '  aria-hidden="true"></i>
                            <input type="email" required name="email" placeholder="Email"  />
                        
                       
                        </div>
                        
                        <div className='input1' >
                            <i class= 'fa fa-lock '  aria-hidden="true"></i>
                            <input type="password" name="password" placeholder=" Enter password" />
                        
                       
                        </div>
                        <div className='btn' >
                            <button >login</button>
                            
                        </div>
                        <div className='link'>
                            <a href='/signup'>don't have account signup </a>

                            
                        </div>


                    </form>
                </div>


            </div>
        </div>
    </div>
  )
}

export default Login