import * as React from 'react';
import './servicesshow.scss';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className='container'
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Startup " {...a11yProps(0)} />
        <Tab label="Taxation" {...a11yProps(1)} />
        <Tab label="Intellectual Property" {...a11yProps(2)} />
        <Tab label="Compliance" {...a11yProps(3)} />
        
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className='row'>
            <div className='col-p'>
                <div className='col'>
                    <h2>Private Limited Company Registration</h2>
                    <p>A Private Limited Company is a privately maintained small business existence, which is one of the highly recommended means to start a business in India. The Companies Act 2013 governs private limited..</p>
                    <a href='/private-limited-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Limited Liability Partnership Registration</h2>
                    <p>Vanigasevai provides a reliable LLP registration service. Consult our experts for assistance.</p>
                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>One Person Company<br/> Registration</h2>
                    <p>A One Person Company is a company that has only one member, as per section 2(62) of the Companies Act, 2013.</p>
                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Partnership Firm <br/>Registration</h2>
                    <p>Do you know that a General Partnership consists of a minimum of two people who agrees to manage a business based on the terms and conditions set in a Partnership Deed? What are you waiting for! Start..</p>
                    
                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>

            

        </div>

      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className='row'>
            <div className='col-p'>
                <div className='col'>
                    <h2>GST  Registration</h2>
                    <p>Vaingasevai offers online GST registration! Without Visiting the Govt. Our GST registration service will help you get registered in seven days in India.</p>
                    <a href='/gst-registration'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>GST Return Filing</h2>
                    <p>GST return filing has become more accessible in today's digital age. With online GST return filing, businesses can save time and resources spent on manual filing.</p>
                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Income Tax Return Filing</h2>
                    <p>Take the help of our expert services to ensure quick, easy, and completely risk-free filing of income tax returns. Vanigasevai professionals will ensure your tax returns are filed on time.</p>

                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2> </h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>
                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>

            

        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className='row'>
            <div className='col-p'>
                <div className='col'>
                    <h2>NGO Registration</h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>
                    <a href='/ngo-registration'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Trademark Registration </h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>
                    <a href='/trademark-registration'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Copyright Registration</h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>

                    <a href='/copy-right-registration'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Pattent Registration </h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>
                    <a href='/patent-registration'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>

            

        </div>  
      
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className='row'>
            <div className='col-p'>
                <div className='col'>
                    <h2>Import & Export Code</h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>
                    <a href='/private-limited-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Limited Liability Partnership Registration</h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>
                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>One Person Company<br/> Registration</h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>

                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                </div>

            </div>
            <div className='col-p'>
                <div className='col'>
                    <h2>Partnership Firm <br/>Registration</h2>
                    <p>Get GST certificate @ Rs 250 with dedicated professional team support GST registration will be done within 3 days. Send all supporting documents and get proficient help and guidance</p>
                    <a href='/one-person-company'><i className='fa fa-arrow-circle-right' aria-hidden="true"></i></a>
                
                
                </div>

            </div>

            

        </div>
      
      </TabPanel>
      
    </Box>
  );
}